/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef, useState } from 'react';
import '../../SCSS/popups.scss';
import RichTextEditor from '../../commonModules/UI/RichTextEditor';
import { parseDateTimeString } from '../../../Utils/dateFormat';
import { getNameInitials, getUserInitials, returnHtmlSnippet, uniqueArray } from '../../../Utils/helpers';
import { addActivityNotes, deleteActivityNotes, updateActivityNotes } from '../../../API/authCurd';
import { IoIosCloseCircle } from "react-icons/io";
import CustomerDocUpload from '../../commonModules/UI/CustomerDocUpload';
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import CopyButton from '../../commonModules/UI/CopyButton';
import ActivityRightHeader from '../../commonModules/UI/ActivityRightHeader';
import ActivityNotesContextProvider from '../../contexts/ActivityNotesContext';
import CallTimeEntryContextProvider from '../../contexts/CallTimeEntryContext';
import AddJobTaskContextProvider from '../../contexts/AddJobTaskContext';
import ClientJobContextProvider from '../../contexts/ClientJobContext';
import { useConfirm } from '../../hooks/use-confirm';
const regex = /<p>\s+<\/p>/

const ActivityPopups = (props) => {
  const [toggle, setToggle] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeValue, setEditModeValue] = useState("");
  const [commentSelectedForEdit, setCommentSelectedForEdit] = useState(null);
  const { mentionArr } = useContext(GlobalSearch)
  const [replyAllText, setReplyAllText] = useState('');
  const topRef = useRef(null)
  const { confirmDelete, DeleteConfirmModal } = useConfirm();

  const richTextEditorSaveBtnHandler = async (taskId, payload, comment, jobId) => {
    return addActivityNotes(payload).then(() => {
      setReplyAllText("")
      if (props.isJob) {
        props.getActivityNotesByTaskId(jobId);
      } else {
        props.getActivityNotesByTaskId(taskId);
      }
    }).catch((error) => {
      console.error('addActivityNotes:error ', error);
    });
  };

  const onDeleteBtnClick = async (e, comment) => {
    e.preventDefault();
    let isConfirm = await confirmDelete("note")
    if (isConfirm) {
      deleteActivityNotes(comment.id)
        .then(() => {
          const updatedActivityNotes = props.activityNotes.filter((item) => item.id !== comment.id);
          props.setActivityNotes(updatedActivityNotes);
          if(props.getUserNOtificationsList){
            props.getUserNOtificationsList(false)
          }
        }).catch((error) => {
          console.error('Error deleting note', error);
        });
    }
  };

  const onEditBtnClick = (e, comment) => {
    e.preventDefault();
    setIsEditMode(true);
    setCommentSelectedForEdit(comment);
    setEditModeValue(comment.note);
  };

  const editModeSaveBtnHandler = async (taskId, payload, comment, jobId) => {
    return updateActivityNotes(comment.id, payload)
      .then(() => {
        if (props.isJob) {
          props.getActivityNotesByTaskId(jobId);
        } else {
          props.getActivityNotesByTaskId(taskId);
        }
        setEditModeValue('');
        setIsEditMode(false);
        setCommentSelectedForEdit(null);
      }).catch((error) => {
        setEditModeValue('');
        setIsEditMode(false);
        console.error('updateActivityNotes:error ', error);
      });
  };

  const closeBtnHandler = () => {
    props.onClick();
    setIsEditMode(false);
  };

  const onReplyAllButtonClick = (e, item) => {
    e.preventDefault()
    let depIds = item?.dept_ids ? item?.dept_ids?.split(",") : []
    let userIds = item?.user_ids ? item?.user_ids?.split(",") : []
    if (item?.created_by) { userIds.push(`${item?.created_by}`) }
    let uniqueUserIds = uniqueArray(userIds)
    let userId = localStorage.getItem('id');
    // Filter out the logged-in user's ID
    let tagUsersArr =  uniqueUserIds.filter(id => id !== userId);
    let htmlStr = returnHtmlSnippet(depIds, tagUsersArr, mentionArr)
    setReplyAllText(htmlStr)
    if(topRef?.current){
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const cancelButtonHandler = (e) => {
    e.preventDefault()
    setIsEditMode(false);
    setCommentSelectedForEdit(null);
    setEditModeValue("");
  }
  
  const copyLink = async (data) => {
    if (data) {
      try {
        if (data?.id) {
          let link = ""
          if (props?.isJob) {
            if (props?.isClientJobReq) {
              link = `${process.env.REACT_APP_BACKEND_URL}/clientjobrequest?job_id=${data.id}&c=true&w=a`
            } else {
              link = `${process.env.REACT_APP_BACKEND_URL}/jobs?job_id=${data.id}&c=true&w=a`
            }
          } else {
            link = `${process.env.REACT_APP_BACKEND_URL}/tasks?task_id=${data.id}&c=true&w=a`
          }
          await navigator.clipboard.writeText(link);
        }
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    }
  }

  return (
    <>
    <div className='popups d-flex justify-content-center align-items-center w-100'>
      <div className='addpopups activity-log'>
        <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
          {props.isJob ? <div className='align-wrap'>
            JOB: {`${props.selectedJob?.customer_details?.name ? props.selectedJob?.customer_details?.name + ' - ' : ''}`}{' '}
            {`${props.selectedJob?.name ? props.selectedJob?.name : ''}`}
            <CopyButton btnText="Copy Link" onClick={() => { copyLink(props.selectedJob) }} />
          </div> :
            <div className='align-wrap'>
              TASK: {`${props.selectedTask?.customer_details?.name ? props.selectedTask?.customer_details?.name + ' - ' : ''}`}{' '}
              {`${props.selectedTask?.project_details?.name ? props.selectedTask?.project_details?.name + ' - ' : ''}`}{' '}
              {`${props.selectedTask?.name ? props.selectedTask?.name : ''}`}
              <CopyButton btnText="Copy Link" onClick={() => { copyLink(props.selectedTask) }} />
            </div>}
          <div className='myIcon' type='button' onClick={closeBtnHandler}>
            <IoIosCloseCircle style={{ width: '28px' }} />
          </div>
        </div>
        <div className='popBody p-3 activity-modal-content'>
          <div className='column-wrap'>
            <div className='add-text-wrap'>
              <h6 className='content-title' ref={topRef}>Notes / Activity</h6>
              <div >
                <div className='w-100 activity-editor'>
                  <div className='initial-text'>
                    <span>{getNameInitials(localStorage.getItem('first_name'), localStorage.getItem('last_name'))}</span>
                  </div>
                  <RichTextEditor
                    selectedTask={props.selectedTask}
                    selectedJob={props.selectedJob}
                    saveButtonHandler={richTextEditorSaveBtnHandler}
                    insertText={replyAllText}
                    isEditMode={false}
                  />
                </div>
                <div className='w-100'>
                </div>
              </div>
              <div className='tabs-wrap'>
                <div className={toggle === 1 ? 'comments-content align-start flex-column' : 'hide-content'}>
                  {props.activityNotes.length > 0 ? (
                    props.activityNotes.map((item) => {
                      return (item?.note != "<p><br></p>" && !regex.test(item?.note)) ? <div key={item.id} className='d-flex activity-user-comments'>
                     
                      <div className='w-100' >
                      <div className='user-details-wrap'>
                      <div className='initial-text pe-3'>
                        <span>{getUserInitials(item?.created_by_user?.first_name, item?.created_by_user?.last_name)}</span>
                      </div>
                        <div className='user-details'>
                          <p className='user-name'>{`${item?.created_by_user?.first_name} ${item?.created_by_user?.last_name ? item?.created_by_user?.last_name : ""}`}</p>

                            <p className='date-time'>Posted on {parseDateTimeString(item.created_at, 1)} {item?.updated_by_user ? `(edited ${parseDateTimeString(item.updated_at, 1)} by ${item?.updated_by_user?.first_name} ${item?.updated_by_user?.last_name ? item?.updated_by_user?.last_name : ""})` : ""}</p>
                        </div>
                        </div>
                        {commentSelectedForEdit?.id === item?.id && isEditMode ? (
                          <div className='text-editor'>
                            <div className='activity-editor'>
                              <RichTextEditor
                                editValue={editModeValue}
                                selectedTask={props.selectedTask}
                                selectedJob={props.selectedJob}
                                saveButtonHandler={editModeSaveBtnHandler}
                                commentSelectedForEdit={commentSelectedForEdit}
                                insertText={editModeValue}
                                isEditMode={true}
                                onCancel={cancelButtonHandler}
                              />
                            </div>
                          </div>
                        ) : (
                              <p className='comment' dangerouslySetInnerHTML={{ __html: item.note }}></p>
                        )}
                        <div className='comment-action'>
                          <a href='' className={`${(commentSelectedForEdit?.id === item?.id && isEditMode) ? "edit-mode" : ""}`} onClick={(e) => onEditBtnClick(e, item)}>
                            Edit
                          </a>
                          <a href='' className={`${(commentSelectedForEdit?.id === item?.id && isEditMode) ? "edit-mode" : ""}`} onClick={(e) => onReplyAllButtonClick(e, item)}>
                            Reply All
                          </a>
                          <a href='' className={`${(commentSelectedForEdit?.id === item?.id && isEditMode) ? "edit-mode" : ""}`} onClick={(e) => onDeleteBtnClick(e, item)}>
                            Delete
                          </a>
                        </div>
                      </div>
                      </div> : null
                    })
                  ) : (
                    <div className='no-activity'>
                      <span>No Activities</span>
                    </div>
                  )}
                </div>
                <div className={toggle === 2 ? 'comments-content' : 'hide-content'}>{/* History content goes here */}</div>
              </div>
            </div>
            <div className='upload-file-main-wrap'>
              <CallTimeEntryContextProvider>
              <ActivityNotesContextProvider>
              <AddJobTaskContextProvider>
              <ClientJobContextProvider>
              <ActivityRightHeader
                selectedTask={props.selectedTask}
                setSelectedTask={props.setSelectedTask}
                setSelectedJob={props.setSelectedJob}
                selectedJob={props.selectedJob}
                isJob={props.isJob}
                onClose={closeBtnHandler}
                fetchList={props?.fetchList}
                fromJob={props.fromJob}
                openJobNotes={props.openJobNotes}
                />
                </ClientJobContextProvider>
              </AddJobTaskContextProvider>
              </ActivityNotesContextProvider>
              </CallTimeEntryContextProvider>
            <div className='file-upoad-wrap'>
              <h6 className='content-title'>Upload Documents</h6>
              <CustomerDocUpload
                multiple={true}
                customRequest={props.documentUploadCustomRequest}
                fileList={props.fileList}
                onChange={props.docOnChangehandler}
                handleDownload={props.handleDownload}
                handleRemove={props.handleRemove}
                />
            </div>
              {props.isJob && <div className='file-upoad-wrap task-doc-list mt-3'>
                <h6 className='content-title'>Files uploaded to Tasks</h6>
                {props?.taskFileList?.length > 0 ? <CustomerDocUpload
                  multiple={true}
                  customRequest={props.documentUploadCustomRequest}
                  fileList={props.taskFileList}
                  onChange={props.docOnChangehandler}
                  handleDownload={props.handleDownload}
                  handleRemove={props.removeTaskDocFromJob}
                /> : <div className='text-center mt-4'>No Data</div>}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    {<DeleteConfirmModal />}
    </>
  );
};

export default ActivityPopups;
