import React from 'react';
import '../../SCSS/MultiselectDropdown.scss'
import { Select } from 'antd';

const MultiselectDropdown = ({ label, options, onChange, value, disabled = false }) => (
  <div className={`multiselect-wrap ${disabled ? 'disabled-field':''}`}>
    <label className='multiselect-label'>{label}</label>
    <Select
      mode="tags"
      style={{
        width: '100%',
      }}
      onChange={onChange}
      tokenSeparators={[';']}
      options={options}
      value={value}
      disabled={disabled ? disabled : false}
    />
  </div>

);
export default MultiselectDropdown;