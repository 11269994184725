/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Offcanvas, Tab, Tabs } from 'react-bootstrap'
import { MdAdd } from "react-icons/md";
import ExistingCompanies from './ExistingCompanies';
import { useCompanyList } from '../../../../hooks/company';
import CompanyCard from './CompanyCard';
import CreateCompanyForm from './CreateCompanyForm';
import { editPrimaryCompany, removeAssociation } from '../../../../../API/authCurd';

const CompanyAssociation = ({ associatedCompanies = [], associatedCompanyIds = [], id, type, section, associate_to, fetchData, sendMessage, primaryKey, handleRemove, handleSetPrimary }) => {
  const [activeKey, setActiveKey] = useState('add_existing')
  const [show, setShow] = useState(false);
  const { companies, getCompanyList } = useCompanyList();
  const [loader, setLoader] = useState(false)

  const onAddButtonClick = (e,) => {
    e.preventDefault()
    setShow(true)
  }

  const onTabSelect = (key) => {
    if (key == 'create_new') {
    } else if (key == 'add_existing') {
      setLoader(true)
      getCompanyList().finally(() => setLoader(false))
    }
    setActiveKey(key)
  }

  function closeCanavasButtonHandler() {
    setShow(false)
  }

  useEffect(() => {
    setLoader(true)
    getCompanyList().finally(() => setLoader(false))
  }, [])

  const handleRemoveCompany = async (e, data) => {
    const isConfirm = await handleRemove(e, data)
    if (isConfirm) {
      let payload = {
        from_object_type: section,
        to_object_type: associate_to,
        associations: [{
          from_id: id,
          to_id: data?.id,
          type: type
        }]
      }
      removeAssociation(payload).then((res) => {
        fetchData(id)
      }).catch((err) => {
      })
    }
  }

  const handleSetPrimaryButton = async (e, data, pk) => {
    let isConfirm = await handleSetPrimary(e, data, pk, type, section, associate_to)
    if (isConfirm) {
      let payload = {
        contact_id: id,
        company_id: data.id
      }
      editPrimaryCompany(payload).then(() => {
        fetchData(id)
      })
    }
  }

  const onCloseCreateForm = ()=>{
    fetchData(id)
    setShow(false)
  }

  return (
    <>
      <div className='association-wrapper'>
        <div className='association-heading'>
          <h6>Companies {`(${associatedCompanies?.length > 0 ? associatedCompanies?.length : 0})`}</h6>
          <a className="mx-4 view-record-link" onClick={(e) => { onAddButtonClick(e) }}><MdAdd />Add</a>
        </div>
        <div className='association-body'>
          {associatedCompanies?.length > 0 ? <>
            {associatedCompanies?.map((item) => {
              return <CompanyCard key={item.id} companyData={item} primaryKey={primaryKey} handleRemove={handleRemoveCompany} handleSetPrimary={handleSetPrimaryButton} section={section} />
            })}
          </> : <p>See the businesses or organizations associated with this record.</p>}
        </div>
      </div>
      <Offcanvas placement='end' show={show} onHide={closeCanavasButtonHandler}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{activeKey == 'create_new' ? 'Create company' : 'Add existing company'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            defaultActiveKey="add_existing"
            className="mb-3"
            activeKey={activeKey}
            onSelect={onTabSelect}
            fill
          >
            <Tab eventKey="create_new" title="Create new">
              <CreateCompanyForm
                sendMessage={sendMessage}
                onClose={() => { onCloseCreateForm() }}
                contactId={id}
                section={section}
              />
            </Tab>
            <Tab eventKey="add_existing" title="Add Existing">
              <ExistingCompanies
                companies={companies}
                activeKey={activeKey}
                loader={loader}
                id={id}
                type={type}
                section={section}
                associate_to={associate_to}
                fetchData={fetchData}
                closeHandler={closeCanavasButtonHandler}
                sendMessage={sendMessage}
                associatedIds={associatedCompanyIds}
              />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default CompanyAssociation