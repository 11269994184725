/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from 'react'
import { message, Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { batchDeleteHSdeals, deleteHSContact, getHSDeals } from "../../../API/authCurd";
import { deletePropertiesIfExists, formatCurrency, getFilterFromLocal, getSortOrder, saveFilterToLocal } from "../../../Utils/helpers";
import { convertToEasternTime } from "../../../Utils/dateFormat";
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { useConfirm } from '../../hooks/use-confirm';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useContactOwners, useContactProps } from '../../hooks/contacts';
import { BasicFilter } from '../../FilterDropdown';
import { dateFilterOpts, hsDealsColumns } from '../../../Utils/staticdata';
import CreateDealButtonDrawer from '../../commonModules/UI/CreateDealButtonDrawer';
import { PreviewButtonDeal, DealsOffCanvas } from '../../commonModules/UI/PreviewButtonDeal';
import '../../SCSS/deals.scss'
import { useAverageDeal, useCloseDeal, useNewDeal, useOpenDeal, useTotalDeal, useWeightedDeal } from '../../hooks/useDealModal';
import BasicDropDownFilter from '../../FilterDropdown/BasicDropDownFilter';
import { FaHandshake } from "react-icons/fa";
import { useDealsPipelines } from '../../hooks/deals';

const paginationPerPage = 50

const HubspotDeals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dealList, setDealList] = useState([])
  const [columns, setColumns] = useState([])
  const navigate = useNavigate()
  const { globalSearch, resetSearch } = useContext(GlobalSearch)
  const [filters, setFilters] = useState({
    hubspot_owner_id: [],
    closedate: [],
    sorter: null,
    global_search: ""
  })
  const { confirmDeleteWindow, DeleteConfirmModal } = useConfirm();
  const { contactOwners, getContactOwners } = useContactOwners()
  const [selectedRows, setSelectedRows] = useState([])
  const [sorterVal, setSorterVal] = useState(null)
  const [isInitialCall, setIsInitialCall] = useState(true)
  const [isMyDeal, setIsMyDeal] = useState(false)
  const [dealsPaging, setDealsPaging] = useState(null)
  const { userData } = useContext(GlobalSearch)
  const [selectedDeal, setSelectedDeal] = useState(null)
  const { TotalDealModal, openDealTotalModal } = useTotalDeal()
  const { WeightedDealModal, openWeightedDealModal } = useWeightedDeal()
  const { OpenDealModal, openOpenDealModal } = useOpenDeal()
  const { CloseDealModal, openCloseDealModal } = useCloseDeal()
  const { NewDealModal, openNewDealModal } = useNewDeal()
  const { AverageDealModal, openAverageDealModal } = useAverageDeal()
  const [showDrawer, setShowDrawer] = useState(false);
  const { dealsPipelines, getDealsPipelines } = useDealsPipelines();
  const [messageApi, contextHolder] = message.useMessage();

  // useEffect(() => {
  //     let permission = checkPermission("Passwords")
  //     if (!permission) {
  //         navigate("/dashboard")
  //     }
  // }, [])

  // Get Customer password pagination
  function getHSDealsPagination(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
    if (isLoader) {
      setIsLoading(true)
    }
    let seachData = { ...searchParams }
    deletePropertiesIfExists(seachData, ["sorter"])
    if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
    if (seachData?.closedate?.length == 0) { deletePropertiesIfExists(seachData, ['closedate']) }

    let globalSearch = seachData?.global_search
    deletePropertiesIfExists(seachData, ['global_search'])

    let searchFilters = { ...seachData }
    if (searchFilters?.closedate?.length >= 0) { searchFilters.closedate = searchFilters.closedate[0] }
    let search = JSON.stringify(searchFilters)

    let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
    sort = JSON.stringify(sort)

    getHSDeals(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
      setIsInitialCall(false)
      setIsLoading(false)
      setDealList(res.data?.deals ? res.data?.deals : [])
      setDealsPaging(res.data?.paging ? res.data?.paging : null)
    }).catch(err => {
      setIsLoading(false)
      setDealList([])
    })
  }

  function getHSDealsPaginationFetchNext(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
    if (isLoader) {
      setIsLoading(true)
    }
    let seachData = { ...searchParams }
    deletePropertiesIfExists(seachData, ["sorter"])
    if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
    if (seachData?.closedate?.length == 0) { deletePropertiesIfExists(seachData, ['closedate']) }

    let globalSearch = seachData?.global_search
    deletePropertiesIfExists(seachData, ['global_search'])

    let searchFilters = { ...seachData }
    if (searchFilters?.closedate?.length >= 0) { searchFilters.closedate = searchFilters.closedate[0] }
    let search = JSON.stringify(searchFilters)

    let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
    sort = JSON.stringify(sort)

    getHSDeals(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
      setIsInitialCall(false)
      setIsLoading(false)
      setDealList((prev) => { return [prev, res.data?.deals ? res.data?.deals : []].flat() })
      setDealsPaging(res.data?.paging ? res.data?.paging : null)
    }).catch(err => {
      setIsLoading(false)
      setDealList([])
    })
  }

  // custom filter check handler
  const customFilterHandler = (opt) => {
    resetSearch()
    let fils = filters
    if (opt?.key) {
      fils[opt.key] = opt?.id ? [opt?.id] : []
    }
    let prevFilter = getFilterFromLocal('hsdeals')
    saveFilterToLocal('hsdeals', { ...prevFilter, ...fils })
    getHSDealsPagination(paginationPerPage, 1, fils, fils.sorter, true)
    setFilters(fils)
  }

  // custom filter reset handler
  const customFilterResetHandler = (key) => {
    let prevFilter = getFilterFromLocal('hsdeals')
    saveFilterToLocal('hsdeals', { ...prevFilter, [key]: [] })
    let fils = filters
    if (key) { fils[key] = [] }
    getHSDealsPagination(paginationPerPage, 1, fils, fils.sorter, true)
  }

  const onNameClickHandler = (e, data) => {
    e.stopPropagation()
    navigate(`/hubspot/deals/${data.id}`)
  }

  async function afterDeleteClickPreview(record) {
    return new Promise(async (resolve, reject) => {
      let msg = `Are you sure you want to delete this contact?`
      let isConfirm = await confirmDeleteWindow(msg)
      if (isConfirm) {
        return deleteHSContact(record?.id).then(() => {
          getHSDealsPagination(paginationPerPage, 1, filters, filters.sorter, true)
          return resolve("deleted" + record?.id)
        }).catch((err) => { return reject("cancel") })
      } else {
        return reject("cancel")
      }
    })
  }

  const onPreviewClick = (e, item) => {
    setSelectedDeal(item)
    setShowDrawer(true)
  }

  const onContactPreviewClose = () => {
    setSelectedDeal(null)
    getHSDealsPagination(paginationPerPage, 1, filters, filters.sorter, true)
  }

  const viewRecordButtonHandler = (e, item) => {
    e.preventDefault()
    if (item.id) {
      navigate(`/hubspot/deals/${item.id}`)
    }
  }

  const onCellHandler = () => {
    return { onClick: (event) => { event.stopPropagation() } }
  }

  const handleContactAssocoationRemove = async (msg, heading) => {
    return await confirmDeleteWindow(msg, heading)
  }

  // Column definition
  useEffect(() => {
    const contactOwnersOpt = contactOwners ? contactOwners?.map((item) => {
      let name = `${item.firstName ? item.firstName : ""} ${item.lastName ? item.lastName : ""}`.trim()
      return { name: name, id: item?.id }
    }) : []
    let ownersDrop = []
    ownersDrop = ownersDrop.concat(contactOwnersOpt);
    ownersDrop.push({ name: "Unassigned", id: 'unassigned' });
    const columnDef = [
      {
        title: 'Deal Name',
        dataIndex: ["properties", 'dealname'],
        key: "dealname",
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, hsDealsColumns["dealname"]),
        width: 150,
        render: (text, record, index) => {
          let name = `${record?.properties?.dealname ? record?.properties?.dealname : ""}`.trim()
          return {
            children: <div className='hover-preview'>
              <Tooltip placement="top" title={name}>
                <FaSquareArrowUpRight className="redirect-icon text-danger" onClick={(e) => onNameClickHandler(e, record)} />
                <span onClick={(e) => onNameClickHandler(e, record)} className='ms-1 redirect-cusor'>{name ? name : '--'}</span>
                <PreviewButtonDeal
                  record={record}
                  onPreviewClick={onPreviewClick}
                />
              </Tooltip>
            </div>
          }
        },
      },
      {
        title: 'Deal Stage',
        dataIndex: ['properties', 'dealstage'],
        key: "dealstage",
        width: 130,
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, hsDealsColumns["dealstage"]),
        // render: (text, record) => text ? text : '--',
        render: (text, record) => {
          let dealPipeline = dealsPipelines?.find((item) => record?.properties?.pipeline == item?.id)
          let dealStages = dealPipeline ? dealPipeline?.stages : []
          let dealStage = dealStages?.find((item) => record?.properties?.dealstage == item?.id)
          let content = `${dealStage?.label ? dealStage?.label : ""} ${dealPipeline?.label ? "(" + dealPipeline?.label + ")" : ""}`.trim()
          return content ? content : '--'
        }
      },
      {
        title: 'Close Date',
        dataIndex: ['properties', 'closedate'],
        key: 'closedate',
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, hsDealsColumns["closedate"]),
        filteredValue: filters.closedate,
        filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={dateFilterOpts} selectionkey={'closedate'} /> },
        width: 100,
        render: (text, record) => {
          return record?.properties?.closedate ? convertToEasternTime(record?.properties?.closedate) : '--'
        }
      },
      {
        title: 'Deal Owner',
        dataIndex: ['properties', 'hubspot_owner_id'],
        key: 'hubspot_owner_id',
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, hsDealsColumns["hubspot_owner_id"]),
        width: 150,
        filteredValue: filters.hubspot_owner_id,
        filterDropdown: (props) => { return <BasicFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={ownersDrop} selectionkey={'hubspot_owner_id'} /> },
        // render: (text, record) => text ? text : '--',
        render: (text, record) => record?.properties?.hubspot_owner_id ? `${record?.properties?.owner_firstname ? record?.properties?.owner_firstname : ''} ${record?.properties?.owner_lastname ? record?.properties?.owner_lastname : ''} ${record?.properties?.owner_email ? "(" + record?.properties?.owner_email + ")" : ''}`.trim() : 'No owner',
      },
      {
        title: 'Amount',
        dataIndex: ['properties', 'amount'],
        key: 'amount',
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, hsDealsColumns["amount"]),
        width: 80,
        className: "deal-amount-cell",
        render: (text, record) => {
          return record?.properties?.amount ? formatCurrency(record?.properties?.amount, false) : '--'
        }
      }
    ];
    setColumns(columnDef)
  }, [dealList, contactOwners, filters, dealsPipelines])

  // table onChange handler function 
  const onTableChangeHandler = (pagination, filters, sorter, extra) => {
    setFilters(prev => {
      if (!sorter || Object.keys(sorter).length === 0 || !sorter.order) {
        return { ...prev, sorter: null };
      }
      return {
        ...prev, sorter: {
          field: hsDealsColumns[sorter.columnKey] ? hsDealsColumns[sorter.columnKey] : "",
          order: sorter.order ? sorter.order == "descend" ? -1 : 1 : null
        }
      };
    });
    const { currentDataSource } = extra
    if (sorter && Object.keys(sorter).length != 0) {
      setSorterVal(`${sorter.columnKey ? sorter.columnKey : null}-${sorter.order ? sorter.order : null}`)
    } else {
      setSorterVal(null);
    }
  }

  useEffect(() => {
    getContactOwners();
    getDealsPipelines()
  }, [])

  const onAllSelectClick = (selected, selectedRows, changeRows) => {
    if (selected) {
      setSelectedRows(selectedRows?.map(item => item.id))
    } else {
      setSelectedRows([])
    }
  }

  const onSelectHandler = (record, selected, selectedRow) => {
    if (selected) {
      setSelectedRows((prev) => [...prev, record?.id])
    } else {
      setSelectedRows((prev) => prev.filter((item) => item != record?.id))
    }
  }

  const getRowSelectionProps = () => {
    return {
      type: "checkbox",
      onSelect: onSelectHandler,
      onSelectAll: onAllSelectClick,
      selectedRowKeys: selectedRows,
    }
  }

  useEffect(() => {
    if (sorterVal && !isInitialCall) {
      getHSDealsPagination(paginationPerPage, 1, filters, filters.sorter, true)
    }
  }, [sorterVal])

  const deleteMultipleDeals = (dealIds) => {
    let data = { deal_ids: dealIds }
    batchDeleteHSdeals(data).then(() => {
      setSelectedRows([])
      getHSDealsPagination(paginationPerPage, 1, filters, filters.sorter, true)
    }).catch((err) => { })
  }

  // batch delete button handler
  const deleteBatchDealHandler = async (record) => {
    let msg = `You're about to delete ${selectedRows?.length} record${selectedRows?.length > 1 ? "s" : ''}. Deleted records can't be restored after 90 days.`
    let isConfirm = await confirmDeleteWindow(msg)
    if (isConfirm) {
      deleteMultipleDeals(selectedRows)
    }
  }

  const onMyDealsClick = (e) => {
    setIsMyDeal(e.target.checked ? true : false)
    let fils = { ...filters }
    if (e.target.checked) {
      let currentUser = contactOwners?.find((item) => item.email == userData.email)
      if (currentUser) {
        fils.hubspot_owner_id = [currentUser?.id]
      } else {
        fils.hubspot_owner_id = []
      }
    } else {
      fils.hubspot_owner_id = []
    }
    getHSDealsPagination(paginationPerPage, 1, fils, filters.sorter, true)
    setFilters(fils)
  }

  const onDealCreateClose = (load = false) => {
    if (load) {
      getHSDealsPagination(paginationPerPage, 1, filters, filters.sorter, true)
    }
  }

  const onRowClick = (e, record) => {
    onNameClickHandler(e, record)
  }

  useEffect(() => {
    if (globalSearch) {
      let searchParams = {
        hubspot_owner_id: [],
        global_search: globalSearch?.trim(),
      }
      setFilters(searchParams);
      let { global_search, ...saveFilter } = searchParams
      saveFilterToLocal('hsdeals', saveFilter)
      getHSDealsPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
    } else {
      let savedFilters = getFilterFromLocal('hsdeals')
      let searchParams = {
        ...filters,
        hubspot_owner_id: savedFilters?.hubspot_owner_id ? savedFilters?.hubspot_owner_id : [],
        global_search: ""
      }
      let { global_search, ...saveFilter } = searchParams
      saveFilterToLocal('hsdeals', saveFilter)
      setFilters(searchParams)
      getHSDealsPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
    }
  }, [globalSearch])

  useEffect(() => {
    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        if (dealsPaging?.next?.after) {
          getHSDealsPaginationFetchNext(paginationPerPage, 1, filters, filters.sorter, true, dealsPaging.next?.after);
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

    const lastRowElement = document.getElementById('last-contact-row');
    if (lastRowElement) {
      observer.observe(lastRowElement);
    }

    return () => {
      if (lastRowElement) {
        observer.unobserve(lastRowElement);
      }
    };
  }, [dealsPaging]);

  const sendAlert = ({ type, content, className }) => {
    if (content) {
      messageApi.open({
        type: type || 'success',
        content: content,
        className: `alert-message ${className ? className : ''}`,
      });
    }
  }

  return (
    <>
    {contextHolder}
    <div className="PageContent">
      <div className='mx-3 mt-2 settingPage password-page hs-contact-page  hubspot-table-page'>
        <div className="header px-3 py-1 d-flex justify-content-between">
          <div><span className='pe-2'>
            <FaHandshake />
          </span>
            Deals </div>
          <div className='d-flex action-btn-wrap'>
            {selectedRows?.length > 0 && <div className='action-btn'>
              <p>{selectedRows?.length} Selected</p>
              <p className='action' onClick={deleteBatchDealHandler}><RiDeleteBin6Line />Delete</p>
            </div>}
            <p>
              <input
                type="checkbox"
                onChange={onMyDealsClick}
                checked={isMyDeal}
              /> My Deals
            </p>
            <CreateDealButtonDrawer onCancel={onDealCreateClose} />
          </div>
        </div>
        {/* <div className='deals-report-wrap'>
          <div className='report-col'>
            <p className='label'>Total Deal Amount</p>
            <h3 onClick={openDealTotalModal}>$8.6K</h3>
            <p className=' mb-0'>Avg $850 per deal</p>
          </div>
          <div className='report-col'>
            <p className='label'>Weighted Deal Amount</p>
            <h3 onClick={openWeightedDealModal}>$1.7K</h3>
            <p className=' mb-0'>Avg $1,716 per deal</p>
          </div>
          <div className='report-col'>
            <p className='label'>Open Deal Amount</p>
            <h3 onClick={openOpenDealModal}>$8.6K</h3>
            <p className=' mb-0'>Avg $850 per deal</p>
          </div>
          <div className='report-col'>
            <p className='label'>Close Won</p>
            <h3 onClick={openCloseDealModal}>$0</h3>
            <p className=' mb-0'></p>
          </div>
          <div className='report-col'>
            <p className='label'>New Deal Amount</p>
            <h3 onClick={openNewDealModal}>$8.6K</h3>
            <p className=' mb-0'>Avg $850 per deal</p>
          </div>
          <div className='report-col'>
            <p className='label'>Average Deal Age</p>
            <h3 onClick={openAverageDealModal}>1 Days</h3>
            <p className=' mb-0'>Avg $850 per deal</p>
          </div>
        </div> */}
        <Table
          columns={columns}
          dataSource={dealList}
          sticky={{
            offsetHeader: 0,
          }}
          rowKey={'id'}
          onChange={onTableChangeHandler}
          pagination={false}
          rowSelection={getRowSelectionProps()}
          onRow={(record, rowIndex) => ({
            id: rowIndex === dealList.length - 1 && dealsPaging?.next?.after ? "last-contact-row" : '',
            // onClick: (e) => { onRowClick(e, record) }
          })}
          loading={{
            indicator: <LoadingOutlined style={{ fontSize: 50, color: '#2c0036', }} spin />,
            spinning: isLoading
          }}
        />
      </div>
      {<DeleteConfirmModal />}
      {<TotalDealModal />}
      {<WeightedDealModal />}
      {<OpenDealModal />}
      {<CloseDealModal />}
      {<NewDealModal />}
      {<AverageDealModal />}
      {showDrawer && <DealsOffCanvas
        record={selectedDeal}
        show={showDrawer}
        setShow={setShowDrawer}
        onClose={onContactPreviewClose}
        viewRecord={viewRecordButtonHandler}
        sendMessage={sendAlert}
        removeContactAssociation={handleContactAssocoationRemove}
      />}
    </div>
    </>
  )
}

export default HubspotDeals