/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Button from '../../Button'
import { Empty, Spin, Button as AntBtn } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'react-bootstrap';
import { sortObjectsByAttribute } from '../../../../../Utils/sortFunctions';
import { CloseOutlined } from '@ant-design/icons';
import { getValidValues } from '../../../../../Utils/helpers';
import CheckboxLabel from '../../CheckboxLabel';
import { editAssociation } from '../../../../../API/authCurd';
import { EditMessage } from '../../../../hooks/hubspot';

const totalSteps = 2

const ExistingDeal = ({ loader, deals, id, type, section, associate_to, fetchData, closeHandler, sendMessage, associatedIds }) => {
    const [step, setStep] = useState(1)
    const [values, setValues] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [searchedItems, setSearhcedItems] = useState([])
    const [isSaving, setIsSaving] = useState(false)

    const addAssociation = () => {
        let payload = {}
        payload.from = id
        payload.to = getValidValues(values)
        payload.type = type
        payload.section = section
        payload.associateTo = associate_to
        setIsSaving(true)
        editAssociation(payload).then((res) => {
            sendMessage({ content: <EditMessage msg={'Your association were updated.'} /> })
            fetchData(id)
            closeHandler()
        }).finally(() => { setIsSaving(false) })
    }

    const nextBtnHandle = (e) => {
        setStep((prev) => { return prev + 1 })
    }

    const prevBtnHandle = (e) => {
        setStep((prev) => { return prev == 0 ? 0 : prev - 1 })
    }

    const saveButtonClick = (e) => {
        addAssociation()
    }

    const checkBoxChangeHandler = (e, item) => {
        let dls = [...values];
        const itemIndex = dls.findIndex((dl) => dl.value == item.value);

        if (itemIndex !== -1) {
            dls.splice(itemIndex, 1);
        } else {
            dls.push(item);
        }

        setValues(dls);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (searchQuery) {
            const filteredItems = deals?.filter((item) =>
                item?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
            setSearhcedItems(filteredItems ? sortObjectsByAttribute(filteredItems, 'label') : [])
        } else {
            setSearhcedItems(deals ? sortObjectsByAttribute(deals, 'label') : [])
        }
    }, [searchQuery, deals])


    const removeContact = (dealId) => {
        setValues((prev) => {
            const updatedValues = prev.filter(item => item.value != dealId);

            if (updatedValues.length === 0) {
                setStep(1);
            }

            return updatedValues;
        })
    }

    return (
        <div className='existing-form'>
            <div className='form-wrapper'>
                {step == 1 && <div className='step-one'>
                    <input
                        placeholder={'Search deals'}
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className='search-box'
                    />
                    {values?.length > 0 && <p>{values?.length} selected</p>}
                    <div className='ListHieght association-list'>
                        {loader ? <div className='list-spinner'>
                            <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: 28, fill: '#fff', color: "#2c0036" }} spin />} />
                        </div> : <>{searchedItems?.length == 0 ?
                            <Empty className='empty-data' image={Empty.PRESENTED_IMAGE_SIMPLE} /> : searchedItems.map((item) => (
                                <Form.Check
                                    key={item.value}
                                    type="checkbox"
                                    // label={`${item?.label ? item?.label : ""}`}
                                    label={<CheckboxLabel label={`${item?.label ? item?.label : "--"}`} isAlreadyAssociated={associatedIds?.includes(item.value)} />}
                                    checked={values.some((dl) => dl.value == item.value) || associatedIds?.includes(item.value)}
                                    onChange={(e) => checkBoxChangeHandler(e, item)}
                                    disabled={associatedIds?.includes(item.value)}
                                />
                            ))}</>}
                    </div>
                </div>}
                {step == 2 && <div className='association-step-two'>
                    {values.map((dl) => (
                        <div key={dl.value} style={{ marginBottom: 12 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6>{dl.label ? dl.label : '--'}</h6>
                                <AntBtn type="text" icon={<CloseOutlined />} onClick={() => removeContact(dl.value)} />
                            </div>
                        </div>
                    ))}</div>}
            </div>

            <div className='association-buttons'>
                {step > 1 && <Button type="button" className='prev-button' onClick={prevBtnHandle}>Prev</Button>}
                <div className='next-btn'>
                    <p>Step {step} of {totalSteps}</p>
                    {step == totalSteps ? <Button type="button" onClick={saveButtonClick} disabled={isSaving || values?.length == 0}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button> : <Button type="button" onClick={nextBtnHandle} disabled={values?.length == 0}>Next</Button>}
                </div>
            </div>
        </div>
    )
}

export default ExistingDeal