/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Offcanvas, Tab, Tabs } from 'react-bootstrap'
import { MdAdd } from "react-icons/md";
import ExistingContacts from './ExistingContact';
import { useContactList } from '../../../../hooks/contacts';
import ContactCard from './ContactCard';
import CreateContactForm from './CreateContactForm';
import { removeAssociation } from '../../../../../API/authCurd';

const ContactAssociation = ({ associatedContacts = [], associatedContactIds = [], id, type, section, associate_to, fetchData, sendMessage, handleRemove }) => {
  const [activeKey, setActiveKey] = useState('add_existing')
  const [show, setShow] = useState(false);
  const { contacts, getContacts } = useContactList();
  const [loader, setLoader] = useState(false)

  const onAddButtonClick = (e,) => {
    e.preventDefault()
    setShow(true)
  }

  const onTabSelect = (key) => {
    if (key == 'create_new') {
    } else if (key == 'add_existing') {
      setLoader(true)
      getContacts().finally(() => setLoader(false))
    }
    setActiveKey(key)
  }

  function closeCanavasButtonHandler() {
    setShow(false)
  }

  useEffect(() => {
    setLoader(true)
    getContacts().finally(() => setLoader(false))
  }, [])

  const handleRemoveContact = async (e, data) => {
    const isConfirm = await handleRemove(e, data)
    if (isConfirm) {
      let payload = {
        from_object_type: section,
        to_object_type: associate_to,
        associations: [{
          from_id: id,
          to_id: data?.id,
          type: type
        }]
      }
      removeAssociation(payload).then((res) => {
        fetchData(id)
      }).catch((err) => {
      })
    }
  }

  const onCloseCreateForm = ()=>{
    fetchData(id)
    setShow(false)
  }

  return (
    <>
      <div className='association-wrapper'>
        <div className='association-heading'>
          <h6>Contacts {`(${associatedContacts?.length > 0 ? associatedContacts?.length : 0})`}</h6>
          <a className="mx-4 view-record-link" onClick={(e) => { onAddButtonClick(e) }}><MdAdd />Add</a>
        </div>
        <div className='association-body'>
          {associatedContacts?.length > 0 ? <>{associatedContacts?.map((item) => {
            return <ContactCard key={item.id} contactData={item} handleRemove={handleRemoveContact} />
          })}</> : <p>See the people associated with this record.</p>}
        </div>
      </div>
      <Offcanvas placement='end' show={show} onHide={closeCanavasButtonHandler} className="existingContact">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{activeKey == 'create_new' ? 'Create contact' : 'Add existing contact'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            defaultActiveKey="add_existing"
            className="mb-3"
            activeKey={activeKey}
            onSelect={onTabSelect}
            fill
          >
            <Tab eventKey="create_new" title="Create new">
              <CreateContactForm
                contactId={id}
                sendMessage={sendMessage}
                type={type}
                section={section}
                associate_to={associate_to}
                onClose={() => { onCloseCreateForm() }}
              />
            </Tab>
            <Tab eventKey="add_existing" title="Add Existing">
              <ExistingContacts
                contacts={contacts}
                activeKey={activeKey}
                loader={loader}
                id={id}
                type={type}
                section={section}
                associate_to={associate_to}
                fetchData={fetchData}
                closeHandler={closeCanavasButtonHandler}
                sendMessage={sendMessage}
                associatedIds={associatedContactIds}
              />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default ContactAssociation