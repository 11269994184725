/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import IMAGE from "../../../images/Login/Login.png"
import { getIconPath, getOriginalFileName } from '../../../Utils/helpers'

const ForumsDocs = ({ card }) => {
    const [documents, setDocuments] = useState([])
    const [imageVideos, setImageVideos] = useState([])

    // Separating Documents
    useEffect(() => {
        let imgVids = []
        let docs = []
        card?.post_images?.forEach((item) => {
            const fileExtension = item.image_url.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)) {
                imgVids.push(item)
            } else {
                docs.push(item)
            }
        })
        setImageVideos(imgVids)
        setDocuments(docs)
    }, [card])

    const openDocument = (file) => {
        if (file?.name && file?.url) {
            window.open(`/view-document?name=${encodeURIComponent(file.name)}&url=${encodeURIComponent(file.url)}`, '_blank', 'noopener noreferrer');
        }
    };

    const handleClick = (event, file) => {
        event.stopPropagation()
        event.preventDefault();
        openDocument(file)
    };

    const renderLinkTag = (file) => {
        const fileExtList = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'pdf', 'doc', 'docx', 'ppt', 'pptx', "xls", "xlsx", 'odt', 'htm', 'html', 'txt', 'mp4']
        const fileExtension = file?.document_name?.split('.')?.pop()?.toLowerCase()
        let originalName = getOriginalFileName(file?.document_name)
        let data = {name: originalName, url: file.image_url}
        
        if (fileExtList.includes(fileExtension)) {
            return <a key={file.photo_id} href={file.image_url} title={originalName} target="_blank" onClick={(e) => handleClick(e, data)} rel="noopener noreferrer">{originalName}</a>
        } else {
            return <a key={file.photo_id} href={file.image_url} title={originalName} download={originalName} target="_blank" rel="noopener noreferrer">{originalName}</a>
        }
    }
    
    const openImageFile = (e, file) => {
        const fileExtList = ['jpg', 'jpeg', 'png', 'gif', 'webp']
        const fileExtension = file?.document_name?.split('.')?.pop()?.toLowerCase()
        let originalName = getOriginalFileName(file?.document_name)
        let data = { name: originalName, url: file.image_url }
        if (fileExtList.includes(fileExtension)) {
            handleClick(e, data)
        }
    }

    return (<>
        {(imageVideos?.length > 0) && <div className='post-image'>
            <img className="post-upload-image" src={imageVideos[0].image_url} alt="upload image" onClick={(e) => { openImageFile(e, imageVideos[0]) }} />
            {(imageVideos?.length > 1) && <span>See more...</span>}
        </div>}
        {(documents?.length > 0) && <div className='file-doc'>
            <img src={`/static/icons/${getIconPath(documents[0]?.image_url)}`} />
            {renderLinkTag(documents[0])}
            {(documents?.length > 1) && <span>See more...</span>}
        </div>}
    </>
    )
}

export default ForumsDocs