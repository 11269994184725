import { Button, Popover, Tooltip } from 'antd'
import React, { useState } from 'react'
import { IoCopy } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const ContactCard = ({ contactData, handleRemove }) => {
    const [copyText, setCopyText] = useState("Copy to clipboard")
    const [copyPhoneText, setCopyPhoneText] = useState("Copy to clipboard")
    const navigate = useNavigate()

    const getName = (data) => {
        let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
        let returnData = name ? name : data?.properties?.email
        return returnData ? returnData : '--'
    }

    const handleCopy = (data) => {
        if (data?.properties?.email) {
            navigator.clipboard.writeText(data.properties.email).then(() => {
                setCopyText('Copied');
                setTimeout(() => {
                    setCopyText('Copy to clipboard');
                }, 2000)
            }).catch(err => { });
        }
    };

    const handlePhoneCopy = (data) => {
        if (data?.properties?.phone) {
            navigator.clipboard.writeText(data.properties.phone).then(() => {
                setCopyPhoneText('Copied');
                setTimeout(() => {
                    setCopyPhoneText('Copy to clipboard');
                }, 2000)
            }).catch(err => { });
        }
    };

    const navigateTo = (e, contactId) => {
        if (contactId) {
            navigate(`/hubspot/contacts/${contactId}`)
        }
    }

    const popoverContent = (
        <div className='d-flex flex-column'>
            <Button className='me-2' danger onClick={(e) => { handleRemove(e, contactData) }}>Remove association</Button>
        </div>
    );

    return (
        <div className='association-card'>
            <Popover
                placement='bottom'
                content={popoverContent}
                trigger="click"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
                <span className='more-button'>More</span>
            </Popover>
            <p className='association-name' onClick={(e) => { navigateTo(e, contactData?.id) }}>{getName(contactData)}</p>
            <p className='association-email'>Email: {contactData?.properties.email ? contactData?.properties.email : '--'} {contactData?.properties.email && <Tooltip placement="top" title={copyText}>
                <IoCopy className={`ms-2`} onClick={() => handleCopy(contactData)} />
            </Tooltip>}</p>
            {contactData?.properties.phone && <p className='association-phone'>{contactData?.properties.phone} <Tooltip placement="top" title={copyPhoneText}>
                <IoCopy className={`ms-2`} onClick={() => handlePhoneCopy(contactData)} />
            </Tooltip></p>}
        </div>
    )
}

export default ContactCard