/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircle } from "react-icons/io";
import Button from './Button';
import 'react-quill/dist/quill.snow.css';
import RichTextEditor from './ForumRichTextEditor';
import { hsNoteSchema, validateFormData } from '../../../Utils/validation';
import { getCurrentTimestamp } from '../../../Utils/dateFormat';
import { hsAssociations } from '../../../Utils/staticdata';
import { addHSNotes } from '../../../API/authCurd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { GlobalSearch } from '../../contexts/GlobalSearchContext';

const getHubspotOwner =(owners, user)=>{
    let hsOwners = owners ? owners : []
    let currentUser = hsOwners?.find((item) => item.email === user.email);
    return currentUser
}

const CreateNoteModal = ({ onCancel, type, associatedObjectId, tagList, owners }) => {
    const [rteInitialValue, setRteInitialValue] = useState("")
    const [formValues, setFormValues] = useState({ hs_note_body: "", hs_at_mentioned_owner_ids: [] })
    const [formError, setFormError] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const { userData } = useContext(GlobalSearch)

    const rteOnChangeHandler = (value) => {
        let err = formError
        if (err.hasOwnProperty("description")) {
            delete err["description"]
        }
        setFormError(err)
        setFormValues((prev) => ({ ...prev, hs_note_body: value }))
    }

    const rteMentionOnChangeHandler = (mentions) => {
        let ids = mentions ? mentions.map(m => m.id) : []
        setFormValues((prev) => ({ ...prev, hs_at_mentioned_owner_ids: ids }))
    }

    const addNewNote = (data) => {
        setIsSaving(true)
        addHSNotes(data).then((res) => {
            setFormValues({ hs_note_body: "", hs_at_mentioned_owner_ids: [] })
            onCancel(true, res.data)
            setIsSaving(false)
        }).catch(() => {
            setIsSaving(false)
        })
    }

    const createNoteHandler = () => {
        validateFormData(hsNoteSchema, formValues).then(() => {
            let ownerData = getHubspotOwner(owners, userData)
            const data = {
                properties: {
                    hs_note_body: formValues.hs_note_body,
                    hs_at_mentioned_owner_ids: formValues.hs_at_mentioned_owner_ids?.length > 0 ? formValues.hs_at_mentioned_owner_ids?.join(';') : "",
                    hs_timestamp: getCurrentTimestamp(),
                    hubspot_owner_id: ownerData ? ownerData?.id : ''
                },
                association: {
                    id: associatedObjectId,
                    type: hsAssociations[type]?.associationType,
                    section: hsAssociations[type]?.associationSection
                }
                // associations: [{
                //     to: {
                //         id: associatedObjectId,
                //         type: type
                //     },
                //     types: [
                //         {
                //             associationCategory: hsAssociations[type]?.associationCategory,
                //             associationTypeId: hsAssociations[type]?.associationTypeId
                //         }
                //     ]
                // }]
            }
            addNewNote(data)
        }).catch((err) => {
            setFormError(err)
        })
    }

    const onCancelBtnClick = () => {
        setFormValues({
            hs_note_body: "",
            hs_at_mentioned_owner_ids: []
        })
        onCancel()
    }

    return (
        <div className={`centerpopups hs-note-modal`}>
            <div className='centerpopups add-pass-modal'>
                <div className='popups d-flex justify-content-center align-items-center w-100'>
                    <div className='addpopups user-form-wrap note-editor'>
                        <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                            <div>Note</div>
                            <div className='myIcon' type='button' >
                                <IoIosCloseCircle onClick={onCancelBtnClick} style={{ width: '28px' }} />
                            </div>
                        </div>
                        <div className='popBody p-3 customer-body'>
                            <RichTextEditor
                                taggingList={tagList}
                                initialValue={rteInitialValue}
                                onChange={rteOnChangeHandler}
                                mentionOnChange={rteMentionOnChangeHandler}
                            />
                            {formError?.hs_note_body ? <span className='ms-2 text-danger'>{formError?.hs_note_body}</span> : null}
                        </div>
                        <div className='mt-auto popfoot w-100 p-2'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <Button disabled={isSaving} type="button" onClick={createNoteHandler}>Create Note {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blurBg"></div>
            </div>
        </div>
    )
}

export default CreateNoteModal