import { useState } from 'react';
import { Button,Upload, Avatar, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { IoIosCloseCircle } from "react-icons/io";
import "../SCSS/ContactPage.scss";
// import Button from '../commonModules/UI/Button';


const useUploadPhotoModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [initials, setInitials] = useState('');  // Add state for initials

  const openModal = ({ initials }) => {
    setInitials(initials ? initials : "");  // Store the initials
    setIsVisible(true);
  };

  const closeModal = () => {
    setIsVisible(false);
    setSelectedFile(null);
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage || Upload.LIST_IGNORE;
  };

  const handleFileChange = (info) => {
    if (info.file.status !== 'removed') {
      setSelectedFile(info.file.originFileObj);
    }
  };

  const handleConfirm = (onConfirm) => {
    if (onConfirm) {
      onConfirm(selectedFile);
    }
    closeModal();
  };

  const ModalComponent = ({ onConfirm }) => {
    if (!isVisible) {
      return null;
    }

    return (
        <div className='centerpopups upload-photo-modal'>
          <div className='popups d-flex justify-content-center align-items-center w-100'>
            <div className='addpopups customer-doc-popup delete-warn'>
              <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                <div>Upload a photo</div>
                <div className='myIcon' type='button' onClick={closeModal}>
                  <IoIosCloseCircle style={{ width: '28px' }} />
                </div>
              </div>
              <div className='popBody p-3 customer-body'>
                <Avatar
                  style={{ marginBottom: 16, backgroundColor: '#87d068' }}
                  src={selectedFile ? URL.createObjectURL(selectedFile) : null}
                >
                  {!selectedFile && initials}
                </Avatar>
                <Upload
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Choose file</Button>
                </Upload>
              </div>
              <div className='mt-auto popfoot w-100 p-2'>
                <div className='d-flex align-items-center justify-content-center footer-btn'>
                  <Button type="button" className="mx-4 cclBtn" onClick={closeModal}>Cancel</Button>
                  <Button type="button" className="yes-button" disabled={!selectedFile} onClick={() => handleConfirm(onConfirm)}>Confirm</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="blurBg"></div>
        </div>
    );
  };

  return {
    openModal,
    ModalComponent,
  };
};

export { useUploadPhotoModal };
