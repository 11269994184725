/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { IoIosCloseCircle } from "react-icons/io";
import Button from './Button';
import 'react-quill/dist/quill.snow.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CustomSelect from './CustomDropdown';
import "../../SCSS/customeselect.scss"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import DynamicFields from './DynamicFields';
import { batchUpdateHSContact } from '../../../API/authCurd';
import { convertDateFormatTwo } from '../../../Utils/dateFormat';

const ContactUpdateModal = ({ onCancel, count = 0, selectedIds, loading, properties }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [selectedProp, setSelectedProp] = useState(null)
    const [value, setValue] = useState(null)

    const onChangeHandler = (e) => {
        if (selectedProp?.fieldType == 'text') {
            setValue(e.target.value)
        } else if (selectedProp?.fieldType == 'date') {
            setValue(convertDateFormatTwo(e))
        }
    }

    const handleSelect = (option) => {
        setSelectedProp(option)
    }

    const updateBtnHandler = () => {
        let data = {
            objectIdList: selectedIds,
            properties: [{ name: selectedProp?.name, value: value }]
        }
        setIsSaving(true)
        batchUpdateHSContact(data).then(() => {
            setIsSaving(false)
            onCancel(true)
        }).catch(() => { setIsSaving(false) })
    }

    const onCancelBtnClick = () => {
        onCancel()
    }

    return (
        <div className={`centerpopups`}>
            <div className='centerpopups add-pass-modal'>
                <div className='popups d-flex justify-content-center align-items-center w-100'>
                    <div className='bulk-edit-popup addpopups'>
                        <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                            <div>{`Bulk edit ${count} record${count > 1 ? "s" : ''}`}</div>
                            <div className='myIcon' type='button' >
                                <IoIosCloseCircle onClick={onCancelBtnClick} style={{ width: '28px' }} />
                            </div>
                        </div>
                        <div className='popBody p-3 customer-body'>
                            {loading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
                                <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
                            </div> : <>
                                <CustomSelect
                                    properties={properties}
                                    selectedOption={selectedProp}
                                    handleSelect={handleSelect}
                                />
                                <div>
                                    <DynamicFields
                                        type={selectedProp?.fieldType}
                                        onChange={onChangeHandler}
                                        label={selectedProp?.label}
                                        name={selectedProp?.name}
                                        value={value}
                                    />
                                </div>
                                <div>
                                    <FloatingLabel label="Status">
                                        <Form.Select
                                            aria-label="Floating label select example" name='type'
                                            value=""
                                            className="phone-select w-100"
                                        >
                                            <option value="4">Active</option>
                                            <option value="3">Inactive</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                            </>}
                        </div>

                        <div className='mt-auto popfoot w-100 p-2'>
                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                <Button disabled={loading} type="button" onClick={onCancelBtnClick} className="cclBtn">Cancel</Button>
                                <Button disabled={isSaving || loading} type="button" onClick={updateBtnHandler}>Update {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blurBg"></div>
            </div>
        </div>
    )
}

export default ContactUpdateModal