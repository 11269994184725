/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import '../../SCSS/popups.scss';
import Button from '../../commonModules/UI/Button';
import { getSingleJob, updateJob } from '../../../API/authCurd';
import ErrorPopup from '../../commonModules/UI/ErrorPopup';
import UpadateJobForm from './updateJobForm';
import { convertDateFormat, parseDateTimeString } from '../../../Utils/dateFormat';
import { convertObject, deletePropertiesIfExists, extractIntegerPart, getNumberBoolean, isObjectNotEmpty, joinHoursMinutes } from '../../../Utils/helpers';
import { jobSchema, validateFormData } from '../../../Utils/validation';
import { IoIosCloseCircle } from "react-icons/io";
import { Alert } from 'antd';
import CopyButton from '../../commonModules/UI/CopyButton';
import { WarningDialog } from '../../commonModules/UI/Dialogue';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const UpdateForm = (props) => {
    const [popMsg, setPopMsg] = useState(false);
    const [isError, setIsError] = useState(false)
    const [errMessage, setErrMessage] = useState();
    const [isUpdating, setIsUpdating] = useState(false)
    const [updatedJobData, setUpdateJobdData] = useState(null)
    const [billWarning, setBillWarning] = useState(false)
    let userType = localStorage.getItem('usertype')
    const alertRef = useRef(null)
    const [showWarning, setShowWarning] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (props?.selectedJob) {
            const { customer, status, type, name, description, deadline, desired_due_date, priority, recurrence_frequency,
                billing_type, projected_hours, assigned_user_id, recurring_job, recurrence_on, completed, job_amount, number_of_hours, hours_remaining,
                projected_hours_string, hours_used, billing_frequency, max_hours, desired_start_date } = props?.selectedJob
            let data = {
                name: name ?? (name === "" ? "" : name),
                type: type ?? (type === "" ? "" : type),
                status: (status == 0 || status == 1) ? 9 : status,
                customer: customer ?? (customer === "" ? "" : customer),
                description: description ?? (description === "" ? "" : description),
                deadline: deadline ? deadline : parseDateTimeString(new Date(), 6),
                desired_due_date: desired_due_date ?? (desired_due_date === "" ? "" : desired_due_date),
                desired_start_date: desired_start_date ?? (desired_start_date === "" ? "" : desired_start_date),
                priority: priority ?? (priority === "" ? "" : priority),
                recurrence_frequency: recurrence_frequency ?? (recurrence_frequency === "" ? "" : recurrence_frequency),
                recurring_job: recurring_job ?? (recurring_job === "" ? "" : recurring_job),
                recurrence_on: recurrence_on ?? (recurrence_on === "" ? "" : recurrence_on),
                billing_type: billing_type ?? (billing_type === "" ? "" : billing_type),
                projected_hours: projected_hours ?? (projected_hours === "" ? "" : projected_hours),
                assigned_user_id: assigned_user_id ?? (assigned_user_id === "" ? "" : assigned_user_id),
                completed: completed ?? (completed === "" ? "" : completed),
                job_amount: job_amount ?? (job_amount === "" ? "" : job_amount),
                unlimited_hours: number_of_hours != -1 ? 0 : 1,
                number_of_hours: number_of_hours == -1 ? "Unlimited" : number_of_hours,
                hours_remaining: hours_remaining == -1 ? "Unlimited" : hours_remaining,
                hours_used: hours_used == 0 ? "0:0" : hours_used,
                billing_frequency: billing_frequency ?? (billing_frequency === "" ? "" : billing_frequency),
                max_hours: max_hours ?? (max_hours === "" ? "" : max_hours),
            }
            if (projected_hours_string) {
                const durationString = projected_hours_string?.split(",")
                data.projected_hours_hrs = durationString?.[0] ? durationString?.[0] : ""
                data.projected_hours_mins = durationString?.[1] ? durationString?.[1] : ""
            } else {
                const projectedHrs = projected_hours?.split(":")
                data.projected_hours_hrs = projectedHrs?.[0] ? Number(projectedHrs?.[0]) : ""
                data.projected_hours_mins = projectedHrs?.[1] ? Number(projectedHrs?.[1]) : ""
            }
            setUpdateJobdData(data)
        }
    }, [props?.selectedJob])

    function getSingleJobData(id) {
        return getSingleJob(id).then((res) => {
            if (res?.data?.job) {
                props.setSelectedJob(res?.data.job)
            }
            return res?.data
        }).catch(err => {
            props.setSelectedJob([])
            return
        })
    }

    function handleSubmit(event, isOk = false) {
        event.preventDefault();
        setIsUpdating(true)

        let data = {
            ...updatedJobData,
            deadline: convertDateFormat(updatedJobData.deadline),
            desired_due_date: convertDateFormat(updatedJobData.desired_due_date),
            desired_start_date: convertDateFormat(updatedJobData.desired_start_date),
            priority: updatedJobData?.priority,
            completed: getNumberBoolean(updatedJobData?.completed),
            recurring_job: getNumberBoolean(updatedJobData?.recurring_job),
            recurrence_on: updatedJobData?.recurring_job ? (updatedJobData?.recurrence_on || "") : "",
            recurrence_frequency: updatedJobData?.recurring_job ? (updatedJobData?.recurrence_frequency || "") : "",
            projected_hours: joinHoursMinutes(extractIntegerPart(updatedJobData?.projected_hours_hrs), extractIntegerPart(updatedJobData?.projected_hours_mins), ":"),
            projected_hours_string: `${extractIntegerPart(updatedJobData?.projected_hours_hrs)},${extractIntegerPart(updatedJobData?.projected_hours_mins)}`,
            confirm_update_job: isOk
        }

        if (data.projected_hours == "0:0" && data.projected_hours_string == ",") {
            data.projected_hours = ""
        }
        
        let unlimitedHours = getNumberBoolean(updatedJobData?.unlimited_hours)
        
        if (unlimitedHours == 1) {
            data.number_of_hours = -1
            data.hours_remaining = -1
        } else {
            data.number_of_hours = updatedJobData.number_of_hours
            data.hours_remaining = updatedJobData.hours_remaining
        }

        delete data["unlimited_hours"]

        if (data.billing_type == "1" || data.billing_type == "3" || data.billing_type == "4") {
            deletePropertiesIfExists(data, ["projected_hours_hrs", "projected_hours_mins"])
        }
        if (data.type != 6) {
            deletePropertiesIfExists(data, ["desired_start_date"])
        }

        validateFormData(jobSchema, data).then(() => {
            setIsSaving(true)
            updateJob(data, props.selectedJob.id)
                .then((res) => {
                    let SuccessfullyMessage = res.data.message;
                    props?.setSuccessMessage(SuccessfullyMessage)
                    if(alertRef?.current){
                        alertRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                    setTimeout(()=>{
                        props.setSuccessMessage("")
                    }, 10000)
                    props.getJobListPagination(props.paginationData.per_page, props.paginationData.current_page, props.filters, false)
                    setIsUpdating(false)
                    setBillWarning(false);
                    setShowWarning(false)
                    getSingleJobData(props.selectedJob.id)
                    setIsSaving(false)
                })
                .catch((err) => {
                    const errFromBackend = convertObject(err.response?.data?.errors ? err.response?.data?.errors : {})
                    if (isObjectNotEmpty(errFromBackend)) {
                        props.setFormError((prev) => ({ ...prev, ...errFromBackend }))
                    } else {
                        let errorMessage = err.response?.data.message || "Something went wrong!"
                        setIsUpdating(false)
                        setIsError(true)
                        setErrMessage(errorMessage)
                        setPopMsg(true)
                    }
                    setIsSaving(false)
                })
        }).catch((err) => {
            props.setFormError(err)
        })
    }

    function errorPopupOnClick() {
        setPopMsg(false)
        if (!isError) {
            props.onClick()
        }
    }

    const cancelButtonHandler = () => {
        setBillWarning(false);
        setShowWarning(false)
        setUpdateJobdData(null)
        props.setSelectedJob([])
        props.setFormError({})
        props.onClick()
        props.setSuccessMessage("")
    }

    const onAlertCloseHandler = () => {
        props?.setSuccessMessage("")
    }

    const copyLink = async (data) => {
        if (data) {
            try {
                if (data?.id) {
                    if (props.isClientRequest) {
                        let link = `${process.env.REACT_APP_BACKEND_URL}/clientjobrequest?job_id=${data.id}&c=true&w=e`
                        await navigator.clipboard.writeText(link);
                    } else {
                        let link = `${process.env.REACT_APP_BACKEND_URL}/jobs?job_id=${data.id}&c=true&w=e`
                        await navigator.clipboard.writeText(link);
                    }
                }
            } catch (error) {
                console.error('Failed to copy:', error);
            }
        }
    }

    const onBilledOkButtonClick = (e) => {
        setBillWarning(false);
        handleSubmit(e, true)
        setShowWarning(false)
    };

    const onBilledNoButtonClick = () => {
        const { status, billing_type, job_amount } = props?.selectedJob
        setBillWarning(false);
        setUpdateJobdData({
            ...updatedJobData,
            job_amount: job_amount ?? (job_amount === "" ? "" : job_amount),
            status: (status == 0 || status == 1) ? 9 : status,
            billing_type: billing_type ?? (billing_type === "" ? "" : billing_type),
        });
        setShowWarning(false)
    };

    const onUpdateButtonClick = (e) => {
        if (billWarning) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
            handleSubmit(e, false)
        }
    }

    if (popMsg) {
        return (
            <ErrorPopup title={errMessage} onClick={errorPopupOnClick} />
        )
    }

    return (
        <>
        <form noValidate>
            <div className='popups d-flex justify-content-center align-items-center job-form-popup'>
                <div className='addpopups'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div className='align-wrap'>Update Job <CopyButton btnText="Copy Link" onClick={() => { copyLink(props.selectedJob) }} /></div>
                        <div className='myIcon' type='button' onClick={cancelButtonHandler}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3'>
                        <div ref={alertRef}>
                            {props.successMessage && <div className='d-flex justify-content-center align-items-center mb-3'>
                                <Alert
                                    message={props.successMessage}
                                    type="success"
                                    showIcon closable
                                    onClose={onAlertCloseHandler}
                                />
                            </div>}
                        </div>
                        <UpadateJobForm
                            jobCodes={props.jobCodes}
                            paymentTerms={props.paymentTerms}
                            customerList={props.customerList}
                            updatedJobData={updatedJobData}
                            setUpdateJobdData={setUpdateJobdData}
                            responsibleUser={props.responsibleUser}
                            formError={props.formError}
                            setFormError={props.setFormError}
                            selectedJob={props.selectedJob}
                            getJobList={props.getJobList}
                            getJobListPagination={props.getJobListPagination}
                            paginationData={props.paginationData}
                            filters={props.filters}
                            isClientRequest={props.isClientRequest}
                            setBillWarning={setBillWarning}
                        />
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button className="mx-4 cclBtn" onClick={cancelButtonHandler}>Cancel</Button>
                            {!(props.isClientRequest && userType == 3) && <div>
                                <Button type="button" onClick={onUpdateButtonClick} disable={isUpdating}>Update {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#fff' }} spin />} />}</Button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </form>
            {showWarning && <WarningDialog
                okClick={onBilledOkButtonClick}
                noClick={onBilledNoButtonClick}
                title={"Warning: Already Billed"}
                description={"This item is already billed or part of a billed item, the requested change would result in an update to the amount billed. Would you like to proceed?"}
            />}
        </>
    );
}

export default UpdateForm;
