/* eslint-disable no-unused-vars */
import React from 'react'
import { Button, Select, Space } from 'antd'

const SelectDropDown = ({ options, onSelect, onReset, onClear, placeholder, searchText, setSearchText, value, setValue }) => {
    const onSearchClick = (value) => {
        setSearchText(value)
    };

    const onClearClick = () => {
        setSearchText("")
        setValue(null)
        onClear()
    };

    const onSelectClick = (value, option) => {
        onSelect(option, value)
    };

    const onResetClick = () => {
        setSearchText("")
        setValue(null)
        onReset()
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());      

    return (
        <div
            style={{ padding: 8, width: '250px' }}
            className='d-flex flex-column'
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Select
                showSearch
                allowClear
                placeholder={placeholder ? placeholder : ""}
                searchValue={searchText}
                onSearch={onSearchClick}
                onSelect={onSelectClick}
                onClear={onClearClick}
                options={options}
                value={value}
                className='mb-2'
                filterOption={filterOption}
            />
            <Space>
                <Button
                    size="small"
                    type='link'
                    onClick={onResetClick}
                >
                    Reset
                </Button>
            </Space>
        </div>
    )
}

export default SelectDropDown