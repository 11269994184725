import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import { FaRegCopy } from "react-icons/fa";

const CopyButton = ({ onClick, tooltip, btnText }) => {
    const [isCopied, setIsCopied] = useState(false)
    let timeoutId = null

    const onClickHandler = () => {
        setIsCopied(true)
        onClick()
        if (timeoutId) {
            clearTimeout(timeoutId); // Clear existing timeout
        }
        timeoutId = setTimeout(() => {
            setIsCopied(false)
        }, 2000);
    }

    return (<>
        <Tooltip title={tooltip || "Copy Link"}>
            <Button type='button' onClick={onClickHandler} className='copy-btn button'>
                {isCopied ? 'Copied' : btnText} <FaRegCopy />
            </Button>
        </Tooltip>
    </>)
}

export default CopyButton