import { useState } from "react";
import { CustomDeleteModal } from "../commonModules/UI/Dialogue";

export const useDeleteHs = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [heading, setHeading] = useState('');
    const [confirmCallback, setConfirmCallback] = useState(null);

    const confirmDeleteWindow = (msg, heading) => {
        return new Promise((resolve) => {
            setModalMessage(msg);
            setHeading(heading);
            setConfirmCallback(() => resolve);
            setShowModal(true);
        });
    };

    const handleConfirm = () => {
        confirmCallback(true);
        setShowModal(false);
    };

    const handleCancel = () => {
        confirmCallback(false);
        setShowModal(false);
    };

    const DeleteHSConfirmModal = () => (
        <CustomDeleteModal
            show={showModal}
            message={modalMessage}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            heading={heading}
        />
    );

    return { confirmDeleteWindow, DeleteHSConfirmModal };
};