/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, message, Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import CreateContactButton from "../../commonModules/UI/CreateContactButtonDrawer";
import { ContactOffCanvas, PreviewButton } from "../../commonModules/UI/PreviewButton";
import { batchDeleteHSContact, deleteHSContact, getHSContacts } from "../../../API/authCurd";
import { deletePropertiesIfExists, findMatchingObject, getFilterFromLocal, getNameInitials, getSortOrder, saveFilterToLocal } from "../../../Utils/helpers";
import { convertToEasternTime, hsHubspotDate } from "../../../Utils/dateFormat";
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { useConfirm } from '../../hooks/use-confirm';
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useContactOwners, useContactProps } from '../../hooks/contacts';
import { BasicFilter } from '../../FilterDropdown';
import { dateFilterOpts, hsContactColumns } from '../../../Utils/staticdata';
import BasicDropDownFilter from '../../FilterDropdown/BasicDropDownFilter';
import ContactUpdateModal from '../../commonModules/UI/ContactUpdateModal';
import { useUploadPhotoModal } from '../../hooks/uploadPhoto';
import CreateNoteModal from '../../commonModules/UI/CreateNoteModal';
import { TiContacts } from "react-icons/ti";
import { useSetPrimary } from '../../hooks/hubspot';


const paginationPerPage = 50

const HubspotContacts = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [contactList, setContactList] = useState([])
    const [columns, setColumns] = useState([])
    const navigate = useNavigate()
    const { globalSearch, resetSearch } = useContext(GlobalSearch)
    const [filters, setFilters] = useState({
        hs_lead_status: [],
        hubspot_owner_id: [],
        lastmodifieddate: [],
        createdate: [],
        sorter: null,
        global_search: ""
    })
    
    const { confirmDeleteWindow, DeleteConfirmModal } = useConfirm();
    const { confirmSetPrimaryWindow, SetPrimaryConfirmModal } = useSetPrimary();
    const { contactProps, getContactProps, loadingContactProps } = useContactProps()
    const { contactOwners, getContactOwners } = useContactOwners()
    const [selectedRows, setSelectedRows] = useState([])
    const [sorterVal, setSorterVal] = useState(null)
    const [isInitialCall, setIsInitialCall] = useState(true)
    const [openBulkEdit, setOpenBulkEdit] = useState(false)
    const [isMyContact, setIsMyContact] = useState(false)
    const [contactPaging, setContactPaging] = useState(null)
    const { userData } = useContext(GlobalSearch)
    const { openModal, ModalComponent } = useUploadPhotoModal();
    const [openCreateNote, setOpenCreateNote] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null)
    const [showDrawer, setShowDrawer] = useState(false);
    const [tagList, setTagList] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    
    // useEffect(() => {
    //     let permission = checkPermission("Passwords")
    //     if (!permission) {
    //         navigate("/dashboard")
    //     }
    // }, [])

    // Get Customer password pagination
    function getHSContactsPagination(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
        if (isLoader) {
            setIsLoading(true)
        }
        let seachData = { ...searchParams }
        deletePropertiesIfExists(seachData, ["sorter"])
        if (seachData?.hs_lead_status?.length == 0) { deletePropertiesIfExists(seachData, ['hs_lead_status']) }
        if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
        if (seachData?.lastmodifieddate?.length == 0) { deletePropertiesIfExists(seachData, ['lastmodifieddate']) }
        if (seachData?.createdate?.length == 0) { deletePropertiesIfExists(seachData, ['createdate']) }

        let globalSearch = seachData?.global_search
        deletePropertiesIfExists(seachData, ['global_search'])

        let searchFilters = { ...seachData }
        if (searchFilters?.lastmodifieddate?.length >= 0) { searchFilters.lastmodifieddate = searchFilters.lastmodifieddate[0] }
        if (searchFilters?.createdate?.length >= 0) { searchFilters.createdate = searchFilters.createdate[0] }
        let search = JSON.stringify(searchFilters)

        let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
        sort = JSON.stringify(sort)

        getHSContacts(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
            setIsInitialCall(false)
            setIsLoading(false)
            setContactList(res.data?.contacts ? res.data?.contacts : [])
            setContactPaging(res.data?.paging ? res.data?.paging : null)
        }).catch(err => {
            setIsLoading(false)
            setContactList([])
        })
    }

    // Get Customer password pagination
    function getHSContactsPaginationFetchNext(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
        if (isLoader) {
            setIsLoading(true)
        }
        let seachData = { ...searchParams }
        deletePropertiesIfExists(seachData, ["sorter"])
        if (seachData?.hs_lead_status?.length == 0) { deletePropertiesIfExists(seachData, ['hs_lead_status']) }
        if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
        if (seachData?.lastmodifieddate?.length == 0) { deletePropertiesIfExists(seachData, ['lastmodifieddate']) }
        if (seachData?.createdate?.length == 0) { deletePropertiesIfExists(seachData, ['createdate']) }

        let globalSearch = seachData?.global_search
        deletePropertiesIfExists(seachData, ['global_search'])

        let searchFilters = { ...seachData }
        if (searchFilters?.lastmodifieddate?.length >= 0) { searchFilters.lastmodifieddate = searchFilters.lastmodifieddate[0] }
        if (searchFilters?.createdate?.length >= 0) { searchFilters.createdate = searchFilters.createdate[0] }
        let search = JSON.stringify(searchFilters)

        let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
        sort = JSON.stringify(sort)

        getHSContacts(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
            setIsInitialCall(false)
            setIsLoading(false)
            setContactList((prev) => { return [prev, res.data?.contacts ? res.data?.contacts : []].flat() })
            setContactPaging(res.data?.paging ? res.data?.paging : null)
        }).catch(err => {
            setIsLoading(false)
            setContactList([])
        })
    }

    // custom filter check handler
    const customFilterHandler = (opt) => {
        resetSearch()
        let fils = { ...filters }
        if (opt?.key) {
            fils[opt.key] = opt?.id ? [opt?.id] : []
        }
        let prevFilter = getFilterFromLocal('hscontacts')
        saveFilterToLocal('hscontacts', { ...prevFilter, ...fils })
        getHSContactsPagination(paginationPerPage, 1, fils, fils.sorter, true)
        setFilters(fils)
    }

    // custom filter reset handler
    const customFilterResetHandler = (key) => {
        let prevFilter = getFilterFromLocal('hscontacts')
        saveFilterToLocal('hscontacts', { ...prevFilter, [key]: [] })
        let fils = filters
        if (key) { fils[key] = [] }
        getHSContactsPagination(paginationPerPage, 1, fils, fils.sorter, true)
    }

    const onNameClickHandler = (e, data) => {
        e.stopPropagation()
        navigate(`/hubspot/contacts/${data.id}`)
    }

    async function afterDeleteClickPreview(record) {
        return new Promise(async (resolve, reject) => {
            let msg = `Are you sure you want to delete this contact?`
            let isConfirm = await confirmDeleteWindow(msg)
            if (isConfirm) {
                return deleteHSContact(record?.id).then(() => {
                    getHSContactsPagination(paginationPerPage, 1, filters, filters.sorter, true)
                    return resolve("deleted" + record?.id)
                }).catch((err) => { return reject("cancel") })
            } else {
                return reject("cancel")
            }
        })
    }

    const onContactPreviewClose = () => {
        getHSContactsPagination(paginationPerPage, 1, filters, filters.sorter, true)
        setSelectedContact(null)
    }

    const viewRecordButtonHandler = (e, item) => {
        e.preventDefault()
        navigate(`/hubspot/contacts/${item.id}`)
    }

    const onPreviewClick = (item) => {
        setSelectedContact(item)
        setShowDrawer(true)
    }

    const onContactCreateClose = (load = false) => {
        if (load) {
            getHSContactsPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }
    }

    const onCellHandler = () => {
        return { onClick: (event) => { event.stopPropagation() } }
    }

    const handleContactAssocoationRemove = async (msg, heading) => {
        return await confirmDeleteWindow(msg, heading)
    }

    // Column definition
    useEffect(() => {
        let leadStatuses = findMatchingObject(contactProps, 'name', 'hs_lead_status')
        const leadFilterOpt = leadStatuses?.options ? leadStatuses?.options.map((item) => ({ name: item?.label, id: item?.value })) : []
        const contactOwnersOpt = contactOwners ? contactOwners?.map((item) => {
            let name = `${item.firstName ? item.firstName : ""} ${item.lastName ? item.lastName : ""}`.trim()
            return { name: name, id: item?.id }
        }) : []
        let currentUser = contactOwners?.find((item) => item?.email == userData?.email)
        let ownersDrop = []
        // if (currentUser) { ownersDrop.push({ name: "Me", id: currentUser.id }); }
        ownersDrop = ownersDrop.concat(contactOwnersOpt);
        ownersDrop.push({ name: "Unassigned", id: 'unassigned' });

        const columnDef = [
            {
                title: 'Name',
                dataIndex: ["properties", 'firstname'],
                key: "firstname",
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["firstname"]),
                width: 200,
                render: (text, record, index) => {
                    let name = `${record?.properties?.firstname ? record?.properties?.firstname : ""} ${record?.properties?.lastname ? record?.properties?.lastname : ""}`.trim()
                    name = name ? name : record?.properties?.email
                    return {
                        children: <div className='hover-preview'>
                            <Tooltip placement="top" title={name}>
                                <FaSquareArrowUpRight className="redirect-icon text-danger" onClick={(e) => onNameClickHandler(e, record)} />
                                <span onClick={(e) => onNameClickHandler(e, record)} className='ms-1 redirect-cusor'>{name ? name : '--'}</span>
                                <PreviewButton
                                    record={record}
                                    onPreviewClick={onPreviewClick}
                                />
                            </Tooltip>
                        </div>
                    }
                },
            },
            {
                title: 'Email',
                dataIndex: ['properties', 'email'],
                key: "email",
                width: 200,
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["email"]),
                render: (text, record) => text ? <a href={`mailto:${text}`}>{text}</a> : '--',
            },
            {
                title: 'Phone Number',
                dataIndex: ['properties', 'phone'],
                key: "phone",
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["phone"]),
                width: 150,
                render: (text, record) => text ? text : '--',
            },
            {
                title: 'Contact Owner',
                dataIndex: ['properties', 'hubspot_owner_id'],
                key: 'hubspot_owner_id',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["hubspot_owner_id"]),
                filteredValue: filters.hubspot_owner_id,
                filterDropdown: (props) => { return <BasicFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={ownersDrop} selectionkey={'hubspot_owner_id'} /> },
                width: 150,
                render: (text, record) => {
                    let contactOwnerName = `${record?.properties.owner_firstname ? record?.properties?.owner_firstname : ""} ${record?.properties?.owner_lastname ? record?.properties?.owner_lastname : ""} ${record?.properties?.owner_email ? "(" + record?.properties?.owner_email + ")" : ''}`.trim()
                    return record?.properties?.hubspot_owner_id ? contactOwnerName : 'No owner'
                }
            },
            {
                title: 'Primary Company',
                dataIndex: ['properties', 'primary_company_name'],
                key: 'primary_company_name',
                // sorter: true,
                // sortOrder: getSortOrder(filters.sorter, hsContactColumns["primary_company_name"]),
                width: 150,
                render: (text, record) => text ? text : '--',
            },
            {
                title: 'Last Activity Date',
                dataIndex: ['properties', 'lastmodifieddate'],
                key: 'lastmodifieddate',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["lastmodifieddate"]),
                filteredValue: filters.lastmodifieddate,
                filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={dateFilterOpts} selectionkey={'lastmodifieddate'} /> },
                width: 200,
                render: (text, record) => {
                    // return record?.properties?.lastmodifieddate ? convertToEasternTime(record?.properties?.lastmodifieddate) : '--'
                    return record?.properties?.lastmodifieddate ? hsHubspotDate(record?.properties?.lastmodifieddate) : '--'
                }
            },
            {
                title: 'Lead Status',
                dataIndex: ['properties', 'hs_lead_status'],
                key: 'hs_lead_status',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["hs_lead_status"]),
                filteredValue: filters.hs_lead_status,
                filterDropdown: (props) => { return <BasicFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={leadFilterOpt} selectionkey={'hs_lead_status'} /> },
                width: 140,
                render: (text, record) => {
                    let status = leadStatuses?.options ? findMatchingObject(leadStatuses?.options, 'value', text) : null
                    return status ? status.label : '--'
                }
            },
            {
                title: 'Creat Date',
                dataIndex: ['properties', 'createdate'],
                key: 'createdate',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsContactColumns["createdate"]),
                filteredValue: filters.createdate,
                filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={dateFilterOpts} selectionkey={'createdate'} /> },
                width: 160,
                render: (text, record) => {
                    // return record?.properties?.createdate ? convertToEasternTime(record?.properties?.createdate) : '--'
                    return record?.properties?.createdate ? hsHubspotDate(record?.properties?.createdate) : '--'
                }
            }
        ];
        setColumns(columnDef)
    }, [contactList, contactProps, contactOwners, filters, userData])

    // table onChange handler function 
    const onTableChangeHandler = (pagination, filters, sorter, extra) => {
        setFilters(prev => {
            if (!sorter || Object.keys(sorter).length === 0 || !sorter.order) {
                return { ...prev, sorter: null };
            }
            return {
                ...prev, sorter: {
                    field: hsContactColumns[sorter.columnKey] ? hsContactColumns[sorter.columnKey] : "",
                    order: sorter.order ? sorter.order == "descend" ? -1 : 1 : null
                }
            };
        });
        const { currentDataSource } = extra
        if (sorter && Object.keys(sorter).length != 0) {
            setSorterVal(`${sorter.columnKey ? sorter.columnKey : null}-${sorter.order ? sorter.order : null}`)
        } else {
            setSorterVal(null);
        }
    }

    useEffect(() => {
        getContactProps();
        getContactOwners();
    }, [])

    const onAllSelectClick = (selected, selectedRows, changeRows) => {
        if (selected) {
            setSelectedRows(selectedRows?.map(item => item.id))
        } else {
            setSelectedRows([])
        }
    }
    const onSelectHandler = (record, selected, selectedRow) => {
        if (selected) {
            setSelectedRows((prev) => [...prev, record?.id])
        } else {
            setSelectedRows((prev) => prev.filter((item) => item != record?.id))
        }
    }

    const getRowSelectionProps = () => {
        return {
            type: "checkbox",
            onSelect: onSelectHandler,
            onSelectAll: onAllSelectClick,
            selectedRowKeys: selectedRows,
        }
    }

    useEffect(() => {
        if (sorterVal && !isInitialCall) {
            getHSContactsPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }
    }, [sorterVal])

    const deleteMultipleContacts = (contactIds) => {
        let data = { contact_ids: contactIds }
        batchDeleteHSContact(data).then(() => {
            setSelectedRows([])
            getHSContactsPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }).catch((err) => { })
    }

    // batch delete button handler
    const deleteBatchContactHandler = async (record) => {
        let msg = `You're about to delete ${selectedRows?.length} record${selectedRows?.length > 1 ? "s" : ''}. Deleted records can't be restored after 90 days.`
        let isConfirm = await confirmDeleteWindow(msg)
        if (isConfirm) {
            deleteMultipleContacts(selectedRows)
        }
    }

    const onBulkEditClick = () => {
        // getContactProps(true)
        setOpenBulkEdit(true)
    }

    const onBulkEditCancelButtonClick = (load = false) => {
        setOpenBulkEdit(false)
    }

    const onMyContactClick = (e) => {
        setIsMyContact(e.target.checked ? true : false)
        let fils = { ...filters }
        if (e.target.checked) {
            let currentUser = contactOwners?.find((item) => item.email == userData.email)
            if (currentUser) {
                fils.hubspot_owner_id = [currentUser?.id]
            } else {
                fils.hubspot_owner_id = []
            }
        } else {
            fils.hubspot_owner_id = []
        }
        getHSContactsPagination(paginationPerPage, 1, fils, filters.sorter, true)
        setFilters(fils)
    }

    const handlePhotoUpload = (file) => { };

    const openPhotoUploadModal = (record) => {
        let initials = getNameInitials(record?.properties?.firstname, record?.properties?.lastname)
        openModal({ initials })
    }

    const openFileRemoveHandler = (record) => { }

    const onCreateNoteButtonClick = (item) => {
        setSelectedContact(item)
        setOpenCreateNote(true)
    }

    const onCreateNoteCancelButtonClick = (load = false) => {
        setSelectedContact(null)
        setOpenCreateNote(false)
    }

    const onRowClick = (e, record) => {
        onNameClickHandler(e, record)
    }

    useEffect(() => {
        if (globalSearch) {
            let searchParams = {
                hs_lead_status: [],
                hubspot_owner_id: [],
                lastmodifieddate: [],
                createdate: [],
                global_search: globalSearch?.trim(),
            }
            setFilters(searchParams);
            let { global_search, ...saveFilter } = searchParams
            saveFilterToLocal('hscontacts', saveFilter)
            getHSContactsPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
        } else {
            let savedFilters = getFilterFromLocal('hscontacts')
            let searchParams = {
                ...filters,
                hs_lead_status: savedFilters?.hs_lead_status ? savedFilters?.hs_lead_status : [],
                hubspot_owner_id: savedFilters?.hubspot_owner_id ? savedFilters?.hubspot_owner_id : [],
                lastmodifieddate: savedFilters?.lastmodifieddate ? savedFilters?.lastmodifieddate : [],
                createdate: savedFilters?.createdate ? savedFilters?.createdate : [],
                global_search: ""
            }
            let { global_search, ...saveFilter } = searchParams
            saveFilterToLocal('hscontacts', saveFilter)
            setFilters(searchParams)
            getHSContactsPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
        }
    }, [globalSearch])

    useEffect(() => {
        const handleIntersection = (entries) => {
            if (entries[0].isIntersecting) {
                if (contactPaging?.next?.after) {
                    getHSContactsPaginationFetchNext(paginationPerPage, 1, filters, filters.sorter, true, contactPaging.next?.after);
                }
            }
        };

        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

        const lastRowElement = document.getElementById('last-contact-row');
        if (lastRowElement) {
            observer.observe(lastRowElement);
        }

        return () => {
            if (lastRowElement) {
                observer.unobserve(lastRowElement);
            }
        };
    }, [contactPaging]);

    useEffect(() => {
        if (contactOwners) {
            let users = contactOwners?.map((item) => ({
                id: item.id,
                value: `${item?.firstName ? item?.firstName : ""} ${item?.lastName ? item?.lastName : ""}`,
            }))
            setTagList(users)
        } else {
            setTagList([])
        }
    }, [contactOwners])

    const sendAlert = ({ type, content, className }) => {
        if (content) {
            messageApi.open({
                type: type || 'success',
                content: content,
                className: `alert-message ${className ? className : ''}`,
            });
        }
    }

    const getNameOrDomain = (data) => {
        let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
        return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
    }

    const handleSetPrimary = async (e, data, pk, type, section, associate_to, contactData) => {      
        let heading = `Set ${getNameOrDomain(data)} as primary`
        let msg = `Replace ${contactData?.properties?.associatedcompanyid ? contactData?.properties?.primary_company_name : ''} as primary`
        return await confirmSetPrimaryWindow(msg, heading)
    }

    return (
        <>
        {contextHolder}
        <div className="PageContent">
            <div className='mx-3 mt-2 settingPage password-page hs-contact-page hubspot-table-page'>
                <div className="header px-3 py-1 d-flex justify-content-between">
                    <div><span className='pe-2'>
                        <TiContacts />
                    </span>
                        Contacts </div>
                    <div className='d-flex action-btn-wrap'>
                        {selectedRows?.length > 0 && <div className='action-btn'>
                            <p>{selectedRows?.length} Selected</p>
                            {/* <p className='action' onClick={onBulkEditClick}><MdOutlineModeEdit />Edit</p> */}
                            <p className='action' onClick={deleteBatchContactHandler}><RiDeleteBin6Line />Delete</p>
                        </div>}
                        <p>
                            <input
                                type="checkbox"
                                onChange={onMyContactClick}
                                checked={isMyContact}
                            /> My Contact
                        </p>
                        <CreateContactButton onCancel={onContactCreateClose} />
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={contactList}
                    sticky={{
                        offsetHeader: 0,
                    }}
                    rowKey={'id'}
                    onChange={onTableChangeHandler}
                    pagination={false}
                    rowSelection={getRowSelectionProps()}
                    onRow={(record, rowIndex) => ({
                        id: rowIndex === contactList.length - 1 && contactPaging?.next?.after ? "last-contact-row" : '',
                        // onClick: (e) => { onRowClick(e, record) }
                    })}
                    loading={{
                        indicator: <LoadingOutlined style={{ fontSize: 50, color: '#2c0036', }} spin />,
                        spinning: isLoading
                    }}
                />
            </div>
            {openBulkEdit && <ContactUpdateModal onCancel={onBulkEditCancelButtonClick} selectedIds={[]} count={selectedRows?.length} loading={loadingContactProps} properties={contactProps} />}
            {openCreateNote && <CreateNoteModal tagList={tagList} onCancel={onCreateNoteCancelButtonClick} type={'contact'} associatedObjectId={selectedContact?.id} />}
            {showDrawer && <ContactOffCanvas
                record={selectedContact}
                show={showDrawer}
                setShow={setShowDrawer}
                afterDelete={afterDeleteClickPreview}
                onClose={onContactPreviewClose}
                viewRecord={viewRecordButtonHandler}
                openFileUpload={openPhotoUploadModal}
                openFileRemove={openFileRemoveHandler}
                createNote={onCreateNoteButtonClick}
                sendMessage={sendAlert}
                removeContactAssociation={handleContactAssocoationRemove}
                handleSetPrimary={handleSetPrimary}
            />}
            {<DeleteConfirmModal />}
            {<SetPrimaryConfirmModal />}
            <ModalComponent onConfirm={handlePhotoUpload} />
        </div>
        </>
    )
}

export default HubspotContacts;