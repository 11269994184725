/* eslint-disable eqeqeq */
import React from 'react'
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { hideText } from '../../../Utils/helpers'

const PasswordField = (props) => {
    const onEyeIconClickHandler = (e, clickedRecord) => {
        props.setSelectedPass((prev) => clickedRecord?.customer_password_id == prev ? null : clickedRecord?.customer_password_id)
    }

    return (
        <div className='d-flex align-items-center justify-content-between'><span>{props.selectedPass == props?.record?.customer_password_id ? props?.record.password : hideText(props?.record.password)}</span><span className='eye-icon cursor-pointer' onClick={(e) => onEyeIconClickHandler(e, props?.record)}>{props.selectedPass == props?.record?.customer_password_id ? <BsEyeSlash /> : <BsEye />}</span></div>
    )
}

export default PasswordField