import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../../SCSS/deals.scss'
function SaveReport() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='save-report-drawer'>
      <button className='button' variant="primary" onClick={handleShow}>
      Save as report
      </button>

      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Save as report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SaveReport;