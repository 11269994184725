import React from 'react'
import MyTooltip from './MyTooltip'
import { IoMdInformationCircle } from "react-icons/io";

const CheckboxLabel = ({ label, disabled, isAlreadyAssociated }) => {
    return (
        <label className={`${disabled ? 'disabled-label' : ''} checkbox-label`}>{label} {isAlreadyAssociated && <>
            <MyTooltip title="This record is already associated.">
                <IoMdInformationCircle />
            </MyTooltip>
        </>}</label>
    )
}

export default CheckboxLabel