/* eslint-disable no-unused-vars */
import '../../SCSS/editTooltip.scss';
import { MdEdit } from "react-icons/md";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import "../../SCSS/ContactPage.scss"
import Button from './Button';
import React, { useEffect, useState } from 'react';
import { Popover, Space, Spin } from 'antd';
import { useFormik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { updateHSDeal } from '../../../API/authCurd';
import { hsDealNameSchema } from '../../../Utils/validation';
import { amountCheck, cleanAnnualRevenue, formatCurrency } from '../../../Utils/helpers';

const initialValues = {
  dealname: '',
  amount: ''
}

const EditForm = ({ errors, getFieldProps, handleSubmit, touched, onCancel, isSaving, values }) => {
  return (<div>
    <div className='mb-3'>
      <FloatingLabel label="Deal Name">
        <Form.Control
          type="text"
          name='dealname'
          placeholder='Deal Name'
          {...getFieldProps("dealname")}
        />
      </FloatingLabel>
      {(touched.dealname && errors.dealname) ? <span className='ms-2 text-danger'>{errors.dealname}</span> : null}
    </div>
    <div className='mb-3'>
      <FloatingLabel label="Deal amount">
        <Form.Control
          type='text'
          placeholder='Deal amount'
          name='amount'
          {...getFieldProps("amount")}
          value={values?.amount}
          onKeyDown={(e) => amountCheck(e)}
        />
      </FloatingLabel>
      {(touched.amount && errors.amount) ? <span className='ms-2 text-danger'>{errors.amount}</span> : null}
    </div>
    <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
      <Button className="cclBtn" onClick={onCancel}>Cancel</Button>
      <Button type="button" disabled={isSaving} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
    </div>
  </div>
  )
};

const EditDealTooltip = ({ record, id, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false)

  const { errors, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
    initialValues,
    // validationSchema: hsDealNameSchema,
    onSubmit: (values) => {
      let data = { ...values }
      data.amount = cleanAnnualRevenue(data.amount || "")
      setIsSaving(true)
      updateHSDeal(id, data).then((res) => {
        setOpen(false)
        onUpdate(true)
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  useEffect(() => {
    if (record && open) {
      Object.keys(initialValues).forEach((field) => {
        let value = record?.properties?.[field] ? record?.properties?.[field] : "";

        if (field === 'amount' && value) {
          value = formatCurrency(value);
        }

        setFieldValue(field, value);
      })
    }
  }, [record, record?.properties, setFieldValue, open])

  const onUpdateHandler = (load = false) => {
    setOpen(false)
    onUpdate(load)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (<Space wrap>
    <Popover
      placement='bottom'
      content={<EditForm
        errors={errors}
        getFieldProps={getFieldProps}
        handleSubmit={handleSubmit}
        touched={touched}
        onCancel={onUpdateHandler}
        isSaving={isSaving}
        values={values}
      />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <MdEdit className='edit-info-icon' />
    </Popover>
  </Space>
  )
}
export default EditDealTooltip;