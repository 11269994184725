/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { getIconPath, getNameInitials, getOriginalFileName, truncateText } from '../../../Utils/helpers';
import { parseDateTimeString } from '../../../Utils/dateFormat';

const HotPost = ({ hpost, onClick }) => {
    const [hotDocuments, setHotDocuments] = useState([])
    const [hotImageVideos, setHotImageVideos] = useState([])

    // Separating Documents
    useEffect(() => {
        let imgVids = []
        let docs = []
        hpost?.post_images.forEach((item) => {
            const fileExtension = item.image_url.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)) {
                imgVids.push(item)
            } else {
                docs.push(item)
            }
        })
        setHotImageVideos(imgVids)
        setHotDocuments(docs)
    }, [hpost])

    const openDocument = (file) => {
        if (file?.name && file?.url) {
            window.open(`/view-document?name=${encodeURIComponent(file.name)}&url=${encodeURIComponent(file.url)}`, '_blank', 'noopener noreferrer');
        }
    };

    const handleClick = (event, file) => {
        event.preventDefault();
        openDocument(file)
    };

    const renderLinkTag = (file) => {
        const fileExtList = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'pdf', 'doc', 'docx', 'ppt', 'pptx', "xls", "xlsx", 'odt', 'htm', 'html', 'txt', 'mp4']
        const fileExtension = file?.document_name?.split('.')?.pop()?.toLowerCase()
        let originalName = getOriginalFileName(file?.document_name)
        let data = {name: originalName, url: file.image_url}
        
        if (fileExtList.includes(fileExtension)) {
            return <a key={file.photo_id} href={file.image_url} title={originalName} target="_blank" onClick={(e) => handleClick(e, data)} rel="noopener noreferrer">{originalName}</a>
        } else {
            return <a key={file.photo_id} href={file.image_url} title={originalName} download={originalName} target="_blank" rel="noopener noreferrer">{originalName}</a>
        }
    }
    
    const openImageFile = (e, file) => {
        const fileExtList = ['jpg', 'jpeg', 'png', 'gif', 'webp']
        const fileExtension = file?.document_name?.split('.')?.pop()?.toLowerCase()
        let originalName = getOriginalFileName(file?.document_name)
        let data = { name: originalName, url: file.image_url }
        if (fileExtList.includes(fileExtension)) {
            handleClick(e, data)
        }
    }

    return (
        <div className="hot-post-wrap">
            <div className="post-header" onClick={() => onClick(hpost)}>
                <div>
                    <div className="user-profile">
                        <span className="initial-text">{getNameInitials(hpost?.user?.first_name, hpost?.user?.last_name)}</span>
                    </div>
                </div>
                <div className="image-content">
                    <div class="user-info">
                        <h3 class="user-name">{hpost?.user?.first_name ? hpost?.user?.first_name : ""} {hpost?.user?.last_name ? hpost?.user?.last_name : ""}</h3>
                        <p class="post-time mb-1">Posted on {parseDateTimeString(hpost.created_at, 11)}</p>
                    </div>
                </div>
            </div>
            <div className="text-image">
                {hpost?.post_title && <p className='mb-0 title'>{hpost?.post_title}</p>}
                <p className='mb-0 post-description' dangerouslySetInnerHTML={{ __html: truncateText(hpost.description, 100) }}></p>
                {hotImageVideos.length > 0 && <>
                    <Carousel>
                        {hotImageVideos.map((item, index) => {
                            const fileExtension = item.image_url.split('.').pop().toLowerCase();
                            if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)) {
                                return <Carousel.Item>
                                    <img key={index} className="post-pic" src={item.image_url} alt="Post Picture" onClick={(e) => { openImageFile(e, item) }} />
                                </Carousel.Item>
                            } else if (['mp4', 'avi', 'mov', 'mkv'].includes(fileExtension)) {
                                return (
                                    <Carousel.Item>
                                        <video key={index} className="post-video" controls width="300">
                                            <source src={item.image_url} type={`video/${fileExtension}`} />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Carousel.Item>
                                );
                            }
                        })}
                    </Carousel>
                </>}
                {hotDocuments.length > 0 && <>
                    <div className='file-doc-wrap'>
                        {hotDocuments.map((item, index) => {
                            let name = item?.document_name
                            let originalName = getOriginalFileName(name)
                            return <div className='file-doc' title={originalName}>
                                <img src={`/static/icons/${getIconPath(item.image_url)}`}/>
                                {renderLinkTag(item)}
                            </div>
                        })}
                    </div>
                </>}
            </div>
        </div>
    )
}

export default HotPost