/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { createContext } from "react";

import { fetchJobs, fetchServiceTypes, getCust } from '../../API/authCurd';

export const AddJobTaskContext = createContext();

const AddJobTaskContextProvider = ({ children }) => {
    const [addJobTaskPopup, setAddJobTaskPopup] = useState(false);
    const [addJobTaskFormError, setAddJobTaskFormError] = useState({})
    const [addJobTaskFormValue, setAddJobTaskFormValue] = useState({})
    const [AddJobTaskServiceTypes, setAddJobTaskServiceTypes] = useState([]);
    const [addJobTaskCustList, setAddJobTaskCustList] = useState([]);
    const [jobsForAddTask, setJobsForAddTask] = useState([]);

    const getServiceTypes = () => {
        fetchServiceTypes().then((res) => {
            if (res.data?.service_types) { setAddJobTaskServiceTypes(res.data?.service_types) }
        }).catch(() => { setAddJobTaskServiceTypes([]) })
    }

    function getCustomersList() {
        getCust().then((res) => {
            setAddJobTaskCustList(res.data.customers)
        }).catch(err => {
            setAddJobTaskCustList([])
        })
    }

    const getJobsData = (id) => {
        fetchJobs(id).then((res) => {
            if (res.data?.jobs) {
                setJobsForAddTask(res.data?.jobs);
            }
        }).catch(() => {
            setJobsForAddTask([]);
        });
    };

    // Add Job Task cancel button handle
    const addJobTaskCancelBtnHandler = () => {
        setAddJobTaskPopup(false)
        setAddJobTaskFormError({})
        setAddJobTaskFormValue({})
    }

    const onJobTaskAddClickHandler = (record) => {
        setAddJobTaskFormError({})
        setAddJobTaskPopup(true)
        getCustomersList()
        getServiceTypes()
        getJobsData(record.customer_id)
        setAddJobTaskFormValue({ customer: record.customer_id, project: record.id })
    }

    return (
        <AddJobTaskContext.Provider value={{
            addJobTaskFormError, setAddJobTaskFormError, addJobTaskFormValue, setAddJobTaskFormValue, AddJobTaskServiceTypes, setAddJobTaskServiceTypes,
            addJobTaskCustList, setAddJobTaskCustList, addJobTaskCancelBtnHandler, jobsForAddTask, setJobsForAddTask, addJobTaskPopup, setAddJobTaskPopup,
            getServiceTypes, getCustomersList, getJobsData, onJobTaskAddClickHandler
        }}>
            {children}
        </AddJobTaskContext.Provider>
    )
}

export default AddJobTaskContextProvider
