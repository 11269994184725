import { DatePicker } from "antd";
import { FloatingLabel, Form } from "react-bootstrap";
import dayjs from "dayjs"
import 'dayjs/locale/en';

const DynamicFields = ({ type, onChange, placeholder, label, name, value }) => {

    switch (type) {
        case 'text':
            return <FloatingLabel label={label}>
                <Form.Control
                    type='text'
                    onChange={onChange}
                    placeholder={placeholder || ''}
                    name={name}
                    value={value}
                />
            </FloatingLabel>
        case 'date':
            return <div className='myInputBox w-100 '>
                {label && <label style={{ display: "block" }}>{label}</label>}
                <DatePicker
                    value={value ? dayjs(value, "MM/DD/YYYY") : ""}
                    onChange={onChange}
                    name={name}
                    format="MM/DD/YYYY"
                    className='myDatePicker'
                    placeholder={placeholder || ""}
                />
            </div>

        default:
            return null
    }
}

export default DynamicFields