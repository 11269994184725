import { useState } from "react";
import { CustomHSDeleteModal } from "../commonModules/UI/Dialogue";
import { useContactOwners } from "./contacts";

export const useDeleteContact = () => {
    const { contactOwners, getContactOwners } = useContactOwners();
    const [showModal, setShowModal] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [msg, setMsg] = useState("");
    const [deleteType, setDeleteType] = useState('restorable');
    const [sendEmail, setSendEmail] = useState(null);

    const confirmDelete = (msg) => {
        return new Promise((resolve) => {
            setConfirmCallback(() => resolve);
            setMsg(msg)
            getContactOwners()
            setShowModal(true);
        });
    };

    const handleConfirm = () => {
        confirmCallback({ deleteType, sendEmail, confirm: true })
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
        setDeleteType('restorable')
        setMsg('')
        setSendEmail(null)
        confirmCallback(null);
    };

    const handleDeleteTypeChange = (e) => {
        setDeleteType(e.target.value);
    };

    const handleSendEmailChange = (value) => {
        setSendEmail(value);
    };

    const DeleteHSConfirmModal = () => (
        <CustomHSDeleteModal
            show={showModal}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            deleteType={deleteType}
            onDeleteTypeChange={handleDeleteTypeChange}
            onSelectChange={handleSendEmailChange}
            contactOwners={contactOwners}
            message={msg}
            sendEmail={sendEmail}
        />
    );

    return { confirmDelete, DeleteHSConfirmModal };
};