/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import SelectDropDown from './SelectDropDown';

export default function BasicDropDownFilter({ confirm, clearFilters, close, filters, setFilters, onFilter, onReset, options, selectionkey, placeholder }) {
    const [searchOptions, setSearchOptions] = useState([])
    const [searchText, setSearchText] = useState("")
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (options) {
            let newArr = options?.map((item) => ({ value: item.id, label: item.name, id: item.id }))
            setSearchOptions(newArr)
        } else {
            setSearchOptions([])
        }
    }, [options])

    // On clear filter function
    function onClearFilter() {
        setFilters({ ...filters, [selectionkey]: [] })
        onReset(selectionkey)
        close()
        clearFilters && clearFilters()
    }

    const onSelect = (option) => {
        close()
        onFilter({ key: selectionkey, id: option?.id })
        confirm()
    };

    useEffect(() => {
        if (filters?.[selectionkey]?.length > 0) {
            let val = filters?.[selectionkey]?.[0];
            setValue(val);
        } else {
            setValue(null);
        }
    }, [filters?.[selectionkey]]);

    return (
        <SelectDropDown
            onSelect={onSelect}
            onClear={onClearFilter}
            onReset={onClearFilter}
            options={searchOptions}
            placeholder={placeholder || "Search..."}
            searchText={searchText}
            setSearchText={setSearchText}
            value={value}
            setValue={setValue}
        />
    )
}
