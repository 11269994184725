import { useEffect, useState } from "react";
import { fetchNotes } from "../../API/authCurd";
import { parseDateTimeString } from "../../Utils/dateFormat";
import { SetPrimaryModal } from "../commonModules/UI/Dialogue";

const useHsNotes = ({ initialLoad = false } = {}) => {
    const [notes, setNotes] = useState([]);
    const [notesPaging, setNotesPaging] = useState(null);

    async function getNotes(type = 'contact', id) {
        return fetchNotes(20, null, null, null, type, id).then((res) => {
            let data = res?.data?.notes ? res?.data?.notes : []
            let paging = res?.data?.paging ? res?.data?.paging : null
            setNotes(data)
            setNotesPaging(paging)
            return Promise.resolve(data)
        }).catch((err) => {
            setNotes([])
            setNotesPaging(null)
            return Promise.reject(err)
        })
    }

    async function fetchNextNotes(afterId, type = 'contact', id) {
        return fetchNotes(20, null, null, afterId, type, id).then((res) => {
            let data = res?.data?.notes ? res?.data?.notes : []
            let paging = res?.data?.paging ? res?.data?.paging : null
            setNotes((prev) => [...prev, ...data])
            setNotesPaging(paging)
            return Promise.resolve(data)
        }).catch((err) => {
            setNotes([])
            setNotesPaging(null)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getNotes();
        }
    }, [initialLoad]);

    return { notes, setNotes, getNotes, notesPaging, setNotesPaging, fetchNextNotes };
}

export const DealCloseDateEditMessage = ({ date }) => {
    return date ? <span><span style={{ fontWeight: 'bold' }}>Success.</span> Close date changed to {parseDateTimeString(date, 3)}</span> : <>{`Changes saved.`}</>
}

export const EditMessage = ({ msg }) => {
    return msg ? <span><span style={{ fontWeight: 'bold' }}>Success.</span> {msg}</span> : <>{`Changes saved.`}</>
}

export const useSetPrimary = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalHeading, setModalHeading] = useState('');
    const [confirmCallback, setConfirmCallback] = useState(null);

    const confirmSetPrimaryWindow = (msg, heading) => {
        return new Promise((resolve) => {
            setModalMessage(msg);
            setModalHeading(heading);
            setConfirmCallback(() => resolve);
            setShowModal(true);
        });
    };

    const handleConfirm = () => {
        confirmCallback(true);
        setShowModal(false);
    };

    const handleCancel = () => {
        confirmCallback(false);
        setShowModal(false);
    };

    const SetPrimaryConfirmModal = () => (
        <SetPrimaryModal
            show={showModal}
            message={modalMessage}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            heading={modalHeading}
        />
    );

    return { confirmSetPrimaryWindow, SetPrimaryConfirmModal };
};

export { useHsNotes };