import React, { useState } from 'react'
import { createContext } from "react";

import { getCust, getSingleJob } from '../../API/authCurd';

export const ClientJobContext = createContext();

const ClientJobContextProvider = ({ children }) => {
    const [clientJobPopup, setClientJobPopup] = useState(false);
    const [clientJobCustomerList, setClientJobCustomerList] = useState([]);
    const [selectedClientJob, setSelectedClientJob] = useState(null);
    const [clientJobError, setClientJobError] = useState({})

    function getClientJobCustomersList() {
        getCust().then((res) => {
            setClientJobCustomerList(res.data.customers)
        }).catch(err => {
            setClientJobCustomerList([])
        })
    }

    // Update Job cancel button handler
    const onClientJobCancelHandler = () => {
        setClientJobError({})
        setClientJobPopup(false)
        setSelectedClientJob(null)
    }

    // Get single job record 
    function getSingleJobData(id) {
        return getSingleJob(id).then((res) => {
            return res?.data
        }).catch(err => {
            return
        })
    }

    const updateClientJobPopup = (data) => {
        setClientJobError({})
        setClientJobPopup(true)
        getSingleJobData(data.id).then((res) => {
            if (res?.job) {
                setSelectedClientJob(res.job)
            }
        }).catch(() => {
            setSelectedClientJob(null)
        })
        getClientJobCustomersList()
    }

    return (
        <ClientJobContext.Provider value={{
            clientJobCustomerList, setClientJobCustomerList, selectedClientJob, setSelectedClientJob, clientJobError, setClientJobError,
            clientJobPopup, setClientJobPopup,
            getClientJobCustomersList, updateClientJobPopup, onClientJobCancelHandler
        }}>
            {children}
        </ClientJobContext.Provider>
    )
}

export default ClientJobContextProvider