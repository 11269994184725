/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IoChevronDownSharp } from "react-icons/io5";
import { IoChevronForwardSharp } from "react-icons/io5";
import { RiMessage2Fill } from "react-icons/ri";
import RichTextEditor from './ForumRichTextEditor';
import { IoPersonCircleSharp } from "react-icons/io5";
import Button from '../../commonModules/UI/Button';
import { convertToEasternTime, getCurrentTimestamp, parseDateTimeString } from '../../../Utils/dateFormat';
import { findMatchingObject, removeOuterDiv } from '../../../Utils/helpers';
import { Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { hsNoteSchema, validateFormData } from '../../../Utils/validation';
import { hsAssociations } from '../../../Utils/staticdata';
import MyTooltip from './MyTooltip';
import { updateHSNotes } from '../../../API/authCurd';
import { Empty } from 'antd';

const comments = [
  {
    title: 'Test demo comment',
    content: 'Lorem Ipsum is simply dummy text of ',
  },
];

const AccordionItem = ({ title, content, item, owners, onDelete, type, associatedObjectId, fetchNotes, tagList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setComment] = useState(false);
  const [rteNoteInitialValue, setRteNoteInitialValue] = useState("")
  const [rteInitialValue, setRteInitialValue] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [formValues, setFormValues] = useState({ hs_note_body: "", hs_at_mentioned_owner_ids: [] })
  const [formError, setFormError] = useState({})

  const toggleAccordion = (open) => {
    if (!isEdit) {
      setIsOpen(open);
    }
  };
  const toggleComment = () => {
    setComment(!isComment);
  };

  const rteOnChangeHandler = (value) => { }

  const rteMentionOnChangeHandler = (mentions) => { }

  const rteNoteOnChangeHandler = (value) => {
    let err = formError
    if (err.hasOwnProperty("description")) {
      delete err["description"]
    }
    setFormError(err)
    setFormValues((prev) => ({ ...prev, hs_note_body: value }))
  }

  const rteNoteMentionOnChangeHandler = (mentions) => {
    let ids = mentions ? mentions.map(m => m.id) : []
    setFormValues((prev) => ({ ...prev, hs_at_mentioned_owner_ids: ids }))
  }

  const getOwnerName = (item) => {
    let name = ''
    let user = owners?.find((u) => u.id == item?.properties?.hubspot_owner_id)
    if (user) {
      name = `by ${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""}`.trim()
    }
    return name
  }

  const getOwnerToolTip = (item) => {
    let name = ''
    let user = owners?.find((u) => u.id == item?.properties?.hubspot_owner_id)
    if (user) {
      name = `${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""} ${user?.email ? '<' + user.email + '>' : ''}`.trim()
    }
    return `By: ${name ? name : '--'}`
  }

  const onEditClick = (e, item) => {
    setIsEdit(true)
    setRteNoteInitialValue(item?.properties?.hs_note_body ? removeOuterDiv(item?.properties?.hs_note_body) : "")
    setFormValues({
      hs_note_body: item?.properties?.hs_note_body ? removeOuterDiv(item?.properties?.hs_note_body) : "",
      hs_at_mentioned_owner_ids: item?.properties?.hs_at_mentioned_owner_ids ? item?.properties?.hs_at_mentioned_owner_ids?.split(';') : []
    })
  }

  const updateNote = (id, data) => {
    setIsSaving(true)
    updateHSNotes(id, data).then((res) => {
      setFormValues({ hs_note_body: "", hs_at_mentioned_owner_ids: [] })
      fetchNotes(true)
      setIsEdit(false)
      setIsSaving(false)
    }).catch(() => {
      setIsSaving(false)
    })
  }

  const editNoteHandler = (e, itemdata) => {
    // validateFormData(hsNoteSchema, formValues).then(() => {
    const data = {
      properties: {
        hs_note_body: formValues.hs_note_body,
        hs_at_mentioned_owner_ids: formValues.hs_at_mentioned_owner_ids?.length > 0 ? formValues.hs_at_mentioned_owner_ids?.join(';') : "",
        // hs_timestamp: getCurrentTimestamp(),
      },
      // associations: [{
      //   to: {
      //     id: associatedObjectId,
      //     type: type
      //   },
      //   types: [
      //     {
      //       associationCategory: hsAssociations[type]?.associationCategory,
      //       associationTypeId: hsAssociations[type]?.associationTypeId
      //     }
      //   ]
      // }]
    }
    updateNote(itemdata.id, data)
    // }).catch((err) => {
    //   setFormError(err)
    // })
  }

  // On delete attachment button handler
  const cancelButtonHandler = (e, item) => {
    e.preventDefault()
    setIsEdit(false)
  }

  const onDeleteButtonClick = (itemid) => {
    onDelete(itemid)
  }

  const noteOnClickHandler = (item) => {
    if (isOpen) {
      onEditClick(null, item)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div className="accordion-item">
      <div className="accordion-title" >
        <div className='title-header-wrap'>
          <div className='title-header' onClick={() => toggleAccordion(!isOpen)}>
            <span >{isOpen ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}</span><p className='noteby'>
              <Tooltip title={getOwnerToolTip(item)}>
                <span className='bold-style'>
                  Note </span>{getOwnerName(item)}</Tooltip></p>
          </div>
          <div className='action-wrap'>
            {isOpen && <>
              {!isEdit && <span onClick={(e) => onEditClick(e, item)}><MyTooltip title={"Edit"}><MdEdit style={{ fill: '#2c0036', color: '#2c0036' }} /></MyTooltip></span>}
              <span onClick={() => { onDeleteButtonClick(item.id) }}><MyTooltip title={"Delete"}><RiDeleteBin6Line /></MyTooltip></span>
            </>}

            <p className='note-date'>{convertToEasternTime(item?.createdAt, 16)}</p>
          </div>
        </div>
        <div className={isOpen ? 'accordion-content' : 'height-box'} style={{ cursor: 'pointer' }}>
          {!isEdit ? <div dangerouslySetInnerHTML={{ __html: item?.properties?.hs_note_body }} onClick={() => { noteOnClickHandler(item) }}></div> : <div className='edit-note-wrapper'>
            <div>
              <RichTextEditor
                taggingList={tagList}
                initialValue={rteNoteInitialValue}
                onChange={rteNoteOnChangeHandler}
                mentionOnChange={rteNoteMentionOnChangeHandler}
              />
              {formError?.hs_note_body ? <span className='ms-2 text-danger'>{formError?.hs_note_body}</span> : null}
            </div>
            <a className="mx-4 cclBtn" onClick={cancelButtonHandler}>Cancel</a>
            <button className='button mt-2 edit-post-button' onClick={(e) => editNoteHandler(e, item)}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#fff' }} spin />} />}</button>
          </div>}
          {/* <div className='add-comment-wrap'>
            <p onClick={toggleComment} className='comment-link'><RiMessage2Fill />{isComment ? 'Add Comment' : 'Hide Comments'}</p>
            {isComment ?
              null : <>
                {comments?.map((item) => {
                  return <div className='comment-details'>
                    <div className='profile-icon'>
                      <IoPersonCircleSharp />
                    </div>
                    <div>
                      <p className='mb-0 profile-name'>Test Node</p>
                      <p className='mb-0 comment'> {item.content}</p>
                    </div>
                  </div>
                })}
                <div>
                  <div className='comment-details'>
                    <div className='profile-icon'>
                      <IoPersonCircleSharp />
                    </div>
                    <div className='editor'>
                      <p className='mb-0 profile-name'>Test Node</p>
                      <RichTextEditor
                        taggingList={mentionArr}
                        initialValue={rteInitialValue}
                        onChange={rteOnChangeHandler}
                        mentionOnChange={rteMentionOnChangeHandler}
                      />
                      <Button type="submit" className="mt-2">Save</Button>
                    </div>
                  </div>
                </div>
              </>
            }
          </div> */}
        </div>
      </div>
    </div>
  );
};

const AccordionNote = ({ items, owners, paging, fetchNext, onDelete, type, associatedObjectId, fetchNotes, tagList, loading }) => {
  const sentinelRef = useRef();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let id = paging?.next?.after ? paging?.next?.after : null
          setIsLoading(true)
          fetchNext(id).finally(() => { setIsLoading(false) });
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [sentinelRef, fetchNext]);

  return (
    <div className="accordion-wrap">
      {loading ? <div className='d-flex justify-content-center align-items-center p-1 mb-2 mt-5'>
        <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
      </div> : <>
        {items?.length > 0 ? <>
          {items.map((item, index) => (
            <AccordionItem tagList={tagList} key={item.id} item={item} owners={owners} onDelete={onDelete} type={type} associatedObjectId={associatedObjectId} fetchNotes={fetchNotes} />
          ))}
          {paging?.next?.after && <>{isLoading ?
            <div className='d-flex justify-content-center align-items-center p-1 mb-2'>
              <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
            </div> :
            <div ref={sentinelRef} style={{ height: '1px' }} />}</>}
        </> : <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>}
      </>}
    </div>
  );
};

export default AccordionNote;
