import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { sortObjectsByAttribute } from "../../../Utils/sortFunctions";
import { groupData } from "../../../Utils/helpers";

const CustomSelect = ({ properties, selectedOption, handleSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupedProps, setGroupedProps] = useState([]);

  useEffect(() => {
    if (properties) {
      let data = groupData(properties)
      setGroupedProps(data)
    } else {
      setGroupedProps([])
    }
  }, [properties])

  const selectHandler = (option) => {
    handleSelect(option)
    setIsOpen(false);
  };

  const renderOptions = (category, options) => {
    const filteredOptions = category?.items?.filter((option) =>
      option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return null;
    }

    return (
      <div key={category?.key}>
        <div className="category-header">{category?.label}</div>
        {filteredOptions?.map((option, index) => (
          <div
            key={index}
            // className={`option ${selectedOption === option ? "selected" : ""}`}
            onClick={() => selectHandler(option)}
          >
            {option?.label}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="select-container">
      <div
        className="select-box"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption ? selectedOption?.label : "Select a property to edit"}</span>
        <span className={`arrow ${isOpen ? "open" : ""}`}><MdOutlineKeyboardArrowDown style={{ width: 20, height: 20 }} /></span>
      </div>
      {isOpen && (
        <div className="dropdown">
          <input
            type="text"
            className="search-box"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="options">
            {sortObjectsByAttribute(groupedProps, 'label').map((prop) => {
              return renderOptions(prop)
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
