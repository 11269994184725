import { useState } from "react";
import { CustomModal } from "../commonModules/UI/Dialogue";

export const useConfirm = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [confirmCallback, setConfirmCallback] = useState(null);

    const confirmDelete = (modelName) => {
        return new Promise((resolve) => {
            setModalMessage(`Are you sure you want to delete this ${modelName ? modelName : "item"}?`);
            setConfirmCallback(() => resolve);
            setShowModal(true);
        });
    };

    const confirmDeleteWindow = (msg) => {
        return new Promise((resolve) => {
            setModalMessage(msg);
            setConfirmCallback(() => resolve);
            setShowModal(true);
        });
    };

    const handleConfirm = () => {
        confirmCallback(true);
        setShowModal(false);
    };

    const handleCancel = () => {
        confirmCallback(false);
        setShowModal(false);
    };

    const DeleteConfirmModal = () => (
        <CustomModal
            show={showModal}
            message={modalMessage}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        />
    );

    return { confirmDelete, confirmDeleteWindow, DeleteConfirmModal };
};