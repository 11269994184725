/* eslint-disable no-unused-vars */
import '../../SCSS/editTooltip.scss';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import React, { useEffect, useState } from 'react';
import { Popover, Space, Tooltip } from 'antd';
import { IoMdInformationCircle } from "react-icons/io";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { IoChevronDownOutline } from "react-icons/io5";

const PhoneForm = ({ onApplyClick, onCancel, phoneValue, isFormatted }) => {
    const [withExtension, setWithExtension] = useState(true)
    const [value, setValue] = useState({ phoneNumber: "", extension: "" })
    const [isValid, setIsValid] = useState("")

    useEffect(() => {
        if (!withExtension) {
            setValue((prev) => ({ ...prev, extension: "" }))
        }
    }, [withExtension])

    useEffect(() => {
        if (typeof phoneValue === 'string' || isFormatted) {
            let data = phoneValue?.split("ext")
            setValue((prev) => ({ ...prev, phoneNumber: data?.[0]?.trim() }))

            if (data?.[0]?.trim()) {
                setIsValid(true)
            }
            if (data?.[1]?.trim() || isFormatted) {
                setWithExtension(true)
                setValue((prev) => ({ ...prev, extension: data?.[1]?.trim() }))
            } else {
                setWithExtension(false)
            }
        }
    }, [phoneValue, isFormatted])

    const changeFieldsClick = () => {
        setWithExtension(!withExtension)
    }

    const onApplyButtonHandler = () => {
        let phoneFieldVal = `${value.phoneNumber} ${value.extension ? "ext " + value.extension : ''}`.trim()
        let isFormatted = withExtension
        onApplyClick(phoneFieldVal, isFormatted)
    }

    const onChangePhoneNumber = (value) => {
        if (typeof value === 'string') { setIsValid(isValidPhoneNumber(value)) }
        setValue((prev) => ({ ...prev, phoneNumber: value }))
    }

    const onChangeExtension = (e) => {
        setValue((prev) => ({ ...prev, extension: e.target.value }))
    }

    const singleFieldOnChange = (e) => {
        setValue((prev) => ({ ...prev, phoneNumber: e.target.value }))
    }

    return <div>
        <h6 className='mb-3 mt-2'>Phone Number</h6>
        <div className='border-wrap'>
            <div className='phone-num-wrap'>
                {withExtension ? <>
                    <PhoneInput
                        placeholder="Phone Number"
                        value={value.phoneNumber ?? ""}
                        onChange={onChangePhoneNumber}
                        className='phone-num'
                        defaultCountry='US'
                    />

                    <FloatingLabel label="Extension" className='extension'>
                        <Form.Control
                            type="text"
                            name='extension'
                            value={value.extension ?? ""}
                            onChange={onChangeExtension}
                            placeholder='Extension'
                        />
                    </FloatingLabel>
                </> : <FloatingLabel label="Phone Number" className='phone-num'>
                    <Form.Control
                        type="text"
                        name='phoneNumber'
                        value={value.phoneNumber ?? ""}
                        placeholder='Phone Number'
                        onChange={singleFieldOnChange}
                    />
                </FloatingLabel>}
            </div>
            {withExtension && <>{!value?.phoneNumber ? <p className='mb-0 mt-1 help-text'>Select a country code and enter a phone number.</p> :
                isValid ? <p className='mb-0 mt-1 help-text'>Validated</p> : <p className='mb-0 mt-1 help-text text-danger'>This phone number is either invalid or is in the wrong format.</p>
            }</>}
        </div>
        <p className='removelink-wrap'><span className='removelink mt-2' onClick={changeFieldsClick}>{withExtension ? "Remove number formatting" : "Apply number formatting"}</span>
            <Tooltip title="This will remove all formatting and requirements in the field. You’ll be able to enter any characters without auto-formatting being applied.">
                <IoMdInformationCircle />
            </Tooltip>
        </p>
        <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
            <Button className="cclBtn" onClick={onCancel} >Cancel</Button>
            <Button type="button" disabled={withExtension && (!value?.phoneNumber?.trim() || !isValid)} onClick={onApplyButtonHandler} >Apply</Button>
        </div>
    </div>
};
const PhoneNumberPopover = ({ onApplyClick, name, type, placeholder, value, placement, disabled, isFormatted }) => {
    const [open, setOpen] = useState(false);
    const [phoneValue, setPhoneValue] = useState('')

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const onApplyClickHandler = (value, isFormatted = false) => {
        onApplyClick(value, isFormatted)
        setOpen(false)
    };

    const onCancelClick = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (open) {
            setPhoneValue(value)
        } else {
            setPhoneValue('')
        }
    }, [open, setPhoneValue, value])

    return (
        <Space wrap style={{ width: '100%' }}>
            <Popover
                placement={placement || 'right'}
                content={<PhoneForm onApplyClick={onApplyClickHandler} onCancel={onCancelClick} phoneValue={phoneValue} isFormatted={isFormatted} />}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
                <div className={`inputSelectWrap ${disabled ? 'disabled-label' : ''}`}>
                    <FloatingLabel label={placeholder}>
                        <Form.Control
                            type={type}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            disabled={disabled || false}
                        />
                    </FloatingLabel>
                    {/* <IoChevronDownOutline className='down-arrow' /> */}
                </div>
            </Popover>
        </Space>
    )
};
export default PhoneNumberPopover;