/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from 'react'
import { message, Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as PiIcons from 'react-icons/pi'
import { useNavigate } from "react-router-dom";
import CreateCompanyButton from "../../commonModules/UI/CreateCompanyButtonDrawer";
import { batchDeleteHSCompany, deleteHSCompany, getHSCompanies } from "../../../API/authCurd";
import { deletePropertiesIfExists, findMatchingObject, getFilterFromLocal, getNameInitials, getSortOrder, saveFilterToLocal } from "../../../Utils/helpers";
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { useConfirm } from '../../hooks/use-confirm';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useContactOwners, useContactProps } from '../../hooks/contacts';
import { BasicFilter } from '../../FilterDropdown';
import { dateFilterOpts, hsCompanyColumns } from '../../../Utils/staticdata';
import BasicDropDownFilter from '../../FilterDropdown/BasicDropDownFilter';
import { CompanyOffCanvas, PreviewButtonCompany } from '../../commonModules/UI/PreviewButtonCompany';
import { convertToEasternTime, hsHubspotDate } from '../../../Utils/dateFormat';
import { useUploadPhotoModal } from '../../hooks/uploadPhoto';
import { FaBuilding } from "react-icons/fa";
const paginationPerPage = 50

const HubspotCompanies = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [companyList, setCompanyList] = useState([])
    const [companyPaging, setCompanyPaging] = useState(null)
    const [columns, setColumns] = useState([])
    const navigate = useNavigate()
    const { globalSearch, resetSearch } = useContext(GlobalSearch)
    const [filters, setFilters] = useState({
        hubspot_owner_id: [],
        hs_lastmodifieddate: [],
        createdate: [],
        sorter: null,
        global_search: ""
    })
    const { confirmDeleteWindow, DeleteConfirmModal } = useConfirm();
    const { contactProps, getContactProps } = useContactProps()
    const { contactOwners, getContactOwners } = useContactOwners()
    const [selectedRows, setSelectedRows] = useState([])
    const [sorterVal, setSorterVal] = useState(null)
    const [isInitialCall, setIsInitialCall] = useState(true)
    const [isMyCompany, setIsMyCompany] = useState(false)
    const { userData } = useContext(GlobalSearch)
    const [showDrawer, setShowDrawer] = useState(false);
    const { openModal, ModalComponent } = useUploadPhotoModal();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    // useEffect(() => {
    //     let permission = checkPermission("Passwords")
    //     if (!permission) {
    //         navigate("/dashboard")
    //     }
    // }, [])

    // Get Customer password pagination
    function getHSCompaniesPagination(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
        if (isLoader) {
            setIsLoading(true)
        }
        let seachData = { ...searchParams }
        deletePropertiesIfExists(seachData, ["sorter"])
        if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
        if (seachData?.hs_lastmodifieddate?.length == 0) { deletePropertiesIfExists(seachData, ['hs_lastmodifieddate']) }
        if (seachData?.createdate?.length == 0) { deletePropertiesIfExists(seachData, ['createdate']) }
        // if (!seachData?.global_search) { deletePropertiesIfExists(seachData, ['global_search']) }

        let globalSearch = seachData?.global_search
        deletePropertiesIfExists(seachData, ['global_search'])

        let searchFilters = { ...seachData }
        if (searchFilters?.hs_lastmodifieddate?.length >= 0) { searchFilters.hs_lastmodifieddate = searchFilters.hs_lastmodifieddate[0] }
        if (searchFilters?.createdate?.length >= 0) { searchFilters.createdate = searchFilters.createdate[0] }
        let search = JSON.stringify(searchFilters)

        let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
        sort = JSON.stringify(sort)

        getHSCompanies(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
            setIsInitialCall(false)
            setIsLoading(false)
            setCompanyList(res.data?.companies ? res.data?.companies : [])
            setCompanyPaging(res.data?.paging ? res.data?.paging : null)
        }).catch(err => {
            setIsLoading(false)
            setCompanyList([])
        })
    }

    function getHSCompaniesPaginationFetchNext(perPage = 50, pageNum, searchParams = {}, sorter = [], isLoader = true, after) {
        if (isLoader) {
            setIsLoading(true)
        }
        let seachData = { ...searchParams }
        deletePropertiesIfExists(seachData, ["sorter"])
        if (seachData?.hubspot_owner_id?.length == 0) { deletePropertiesIfExists(seachData, ['hubspot_owner_id']) }
        if (seachData?.hs_lastmodifieddate?.length == 0) { deletePropertiesIfExists(seachData, ['hs_lastmodifieddate']) }
        if (seachData?.createdate?.length == 0) { deletePropertiesIfExists(seachData, ['createdate']) }

        let globalSearch = seachData?.global_search
        deletePropertiesIfExists(seachData, ['global_search'])

        let searchFilters = { ...seachData }
        if (searchFilters?.hs_lastmodifieddate?.length >= 0) { searchFilters.hs_lastmodifieddate = searchFilters.hs_lastmodifieddate[0] }
        if (searchFilters?.createdate?.length >= 0) { searchFilters.createdate = searchFilters.createdate[0] }
        let search = JSON.stringify(searchFilters)

        let sort = sorter?.field ? [{ property: sorter?.field, order: sorter.order == -1 ? "DESCENDING" : "ASCENDING" }] : []
        sort = JSON.stringify(sort)

        getHSCompanies(perPage, pageNum, search, sort, after, globalSearch).then((res) => {
            setIsInitialCall(false)
            setIsLoading(false)
            setCompanyList((prev) => { return [prev, res.data?.companies ? res.data?.companies : []].flat() })
            setCompanyPaging(res.data?.paging ? res.data?.paging : null)
        }).catch(err => {
            setIsLoading(false)
            setCompanyList([])
        })
    }

    const viewRecordButtonHandler = (e, item) => {
        e.preventDefault()
        navigate(`/hubspot/companies/${item.id}`)
    }


    // custom filter check handler
    const customFilterHandler = (opt) => {
        resetSearch()
        let fils = filters
        if (opt?.key) {
            fils[opt.key] = opt?.id ? [opt?.id] : []
        }
        let prevFilter = getFilterFromLocal('hscompany')
        saveFilterToLocal('hscompany', { ...prevFilter, ...fils })
        getHSCompaniesPagination(paginationPerPage, 1, fils, fils.sorter, true)
        setFilters(fils)
    }

    // custom filter reset handler
    const customFilterResetHandler = (key) => {
        let prevFilter = getFilterFromLocal('hscompany')
        saveFilterToLocal('hscompany', { ...prevFilter, [key]: [] })
        let fils = filters
        if (key) { fils[key] = [] }
        getHSCompaniesPagination(paginationPerPage, 1, fils, fils.sorter, true)
    }

    const onNameClickHandler = (e, data) => {
        e.stopPropagation()
        navigate(`/hubspot/companies/${data.id}`)
    }

    async function afterDeleteClickPreview(record) {
        return new Promise(async (resolve, reject) => {
            let msg = `Are you sure you want to delete this company?`
            let isConfirm = await confirmDeleteWindow(msg)
            if (isConfirm) {
                return deleteHSCompany(record?.id).then(() => {
                    getHSCompaniesPagination(paginationPerPage, 1, filters, filters.sorter, true)
                    return resolve("deleted" + record?.id)
                }).catch((err) => { return reject("cancel") })
            } else {
                return reject("cancel")
            }
        })
    }

    const onCompanyPreviewClose = () => {
        getHSCompaniesPagination(paginationPerPage, 1, filters, filters.sorter, true)
        setSelectedCompany(null)
    }

    const onCompanyCreateClose = (load = false) => {
        if (load) {
            getHSCompaniesPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }
    }

    const onPreviewClick = (item) => {
        setSelectedCompany(item)
        setShowDrawer(true)
    }

    const handleContactAssocoationRemove = async (msg, heading) => {
        return await confirmDeleteWindow(msg, heading)
    }

    // Column definition
    useEffect(() => {
        let leadStatuses = findMatchingObject(contactProps, 'name')
        const contactOwnersOpt = contactOwners ? contactOwners?.map((item) => {
            let name = `${item.firstName ? item.firstName : ""} ${item.lastName ? item.lastName : ""}`.trim()
            return { name: name, id: item?.id }
        }) : []
        let ownersDrop = []
        ownersDrop = ownersDrop.concat(contactOwnersOpt);
        ownersDrop.push({ name: "Unassigned", id: 'unassigned' });
        const columnDef = [
            {
                title: 'Compony Name',
                dataIndex: ["properties", 'name'],
                key: "name",
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["name"]),
                // filteredValue: filters.name,
                // filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={contactOwnersOpt} selectionkey={'hubspot_owner_id'} /> },
                width: 200,
                render: (text, record, index) => {
                    let name = `${record?.properties?.name ? record?.properties?.name : ""}`.trim()
                    return {
                        children: <div className='hover-preview'>
                            <Tooltip placement="top" title={name}>
                                <FaSquareArrowUpRight className="redirect-icon text-danger" onClick={(e) => onNameClickHandler(e, record)} />
                                <span onClick={(e) => onNameClickHandler(e, record)} className='ms-1 redirect-cusor'>{name ? name : '--'}</span>
                                <PreviewButtonCompany
                                    record={record}
                                    onPreviewClick={onPreviewClick}
                                />
                            </Tooltip>
                        </div>
                    }
                },
            },
            {
                title: 'Company Owner',
                dataIndex: ['properties', 'hubspot_owner_id'],
                key: 'hubspot_owner_id',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["hubspot_owner_id"]),
                filteredValue: filters.hubspot_owner_id,
                filterDropdown: (props) => { return <BasicFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={ownersDrop} selectionkey={'hubspot_owner_id'} /> },
                width: 200,
                render: (text, record) => {
                    let contactOwnerName = `${record?.properties.owner_firstname ? record?.properties?.owner_firstname : ""} ${record?.properties?.owner_lastname ? record?.properties?.owner_lastname : ""} ${record?.properties?.owner_email ? "(" + record?.properties?.owner_email + ")" : ''}`.trim()
                    return record?.properties?.hubspot_owner_id ? contactOwnerName : 'No owner'
                }
            },
            {
                title: 'Create Date',
                dataIndex: ['properties', 'createdate'],
                key: "createdate",
                filteredValue: filters.createdate,
                filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={dateFilterOpts} selectionkey={'createdate'} /> },
                width: 180,
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["createdate"]),
                render: (text, record) => {
                    // return record?.properties?.createdate ? convertToEasternTime(record?.properties?.createdate) : '--'
                    return record?.properties?.createdate ? hsHubspotDate(record?.properties?.createdate) : '--'
                }
            },
            {
                title: 'Phone Number',
                dataIndex: ['properties', 'phone'],
                key: "phone",
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["phone"]),
                width: 150,
                render: (text, record) => text ? text : '--',
            },
            {
                title: 'Last Activity Date',
                dataIndex: ['properties', 'hs_lastmodifieddate'],
                key: "hs_lastmodifieddate",
                filteredValue: filters.hs_lastmodifieddate,
                filterDropdown: (props) => { return <BasicDropDownFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} options={dateFilterOpts} selectionkey={'hs_lastmodifieddate'} /> },
                width: 180,
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["hs_lastmodifieddate"]),
                render: (text, record) => {
                    // return record?.properties?.hs_lastmodifieddate ? convertToEasternTime(record?.properties?.hs_lastmodifieddate) : '--'
                    return record?.properties?.hs_lastmodifieddate ? hsHubspotDate(record?.properties?.hs_lastmodifieddate) : '--'
                }
            },
            {
                title: 'City',
                dataIndex: ['properties', 'city'],
                key: 'city',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["city"]),
                width: 150,
                render: (text, record) => text ? text : '--',
            },
            {
                title: 'Country/Region',
                dataIndex: ['properties', 'country'],
                key: 'country',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["country"]),
                width: 160,
                render: (text, record) => text ? text : '--',
            },
            {
                title: 'Industry',
                dataIndex: ['properties', 'industry'],
                key: 'industry',
                sorter: true,
                sortOrder: getSortOrder(filters.sorter, hsCompanyColumns["industry"]),
                width: 180,
                render: (text, record) => text ? text : '--',
            }
        ];
        setColumns(columnDef)
    }, [companyList, contactProps, filters, contactOwners])

    // table onChange handler function 
    const onTableChangeHandler = (pagination, filters, sorter, extra) => {
        setFilters(prev => {
            if (!sorter || Object.keys(sorter).length === 0 || !sorter.order) {
                return { ...prev, sorter: null };
            }
            return {
                ...prev, sorter: {
                    field: hsCompanyColumns[sorter.columnKey] ? hsCompanyColumns[sorter.columnKey] : "",
                    order: sorter.order ? sorter.order == "descend" ? -1 : 1 : null
                }
            };
        });
        const { currentDataSource } = extra
        if (sorter && Object.keys(sorter).length != 0) {
            setSorterVal(`${sorter.columnKey ? sorter.columnKey : null}-${sorter.order ? sorter.order : null}`)
        } else {
            setSorterVal(null);
        }
    }

    useEffect(() => {
        getContactProps();
        getContactOwners();
    }, [])

    const onAllSelectClick = (selected, selectedRows, changeRows) => {
        if (selected) {
            setSelectedRows(selectedRows?.map(item => item.id))
        } else {
            setSelectedRows([])
        }
    }
    const onSelectHandler = (record, selected, selectedRow) => {
        if (selected) {
            setSelectedRows((prev) => [...prev, record?.id])
        } else {
            setSelectedRows((prev) => prev.filter((item) => item != record?.id))
        }
    }

    const getRowSelectionProps = () => {
        return {
            type: "checkbox",
            onSelect: onSelectHandler,
            onSelectAll: onAllSelectClick,
            selectedRowKeys: selectedRows,
        }
    }

    useEffect(() => {
        if (sorterVal && !isInitialCall) {
            getHSCompaniesPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }
    }, [sorterVal])

    const deleteMultipleCompany = (contactIds) => {
        let data = { company_ids: contactIds }
        batchDeleteHSCompany(data).then(() => {
            setSelectedRows([])
            getHSCompaniesPagination(paginationPerPage, 1, filters, filters.sorter, true)
        }).catch((err) => { })
    }

    // batch delete button handler
    const deleteBatchCompanyHandler = async (record) => {
        let msg = `You're about to delete ${selectedRows?.length} record${selectedRows?.length > 1 ? "s" : ''}. Deleted records can't be restored after 90 days.`
        let isConfirm = await confirmDeleteWindow(msg)
        if (isConfirm) {
            deleteMultipleCompany(selectedRows)
        }
    }

    const onMyContactClick = (e) => {
        setIsMyCompany(e.target.checked ? true : false)
        let fils = { ...filters }
        if (e.target.checked) {
            let currentUser = contactOwners?.find((item) => item.email == userData.email)
            if (currentUser) {
                fils.hubspot_owner_id = [currentUser?.id]
            } else {
                fils.hubspot_owner_id = []
            }
        } else {
            fils.hubspot_owner_id = []
        }
        getHSCompaniesPagination(paginationPerPage, 1, fils, filters.sorter, true)
        setFilters(fils)
    }

    const handlePhotoUpload = (file) => { };

    const openPhotoUploadModal = (record) => {
        let initials = getNameInitials(record?.properties?.firstname, record?.properties?.lastname)
        openModal({ initials })
    }

    const openFileRemoveHandler = (record) => { }

    useEffect(() => {
        if (globalSearch) {
            let searchParams = {
                hubspot_owner_id: [],
                hs_lastmodifieddate: [],
                createdate: [],
                global_search: globalSearch?.trim(),
            }
            setFilters(searchParams);
            let { global_search, ...saveFilter } = searchParams
            saveFilterToLocal('hscompany', saveFilter)
            getHSCompaniesPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
        } else {
            let savedFilters = getFilterFromLocal('hscompany')
            let searchParams = {
                ...filters,
                hubspot_owner_id: savedFilters?.hubspot_owner_id ? savedFilters?.hubspot_owner_id : [],
                hs_lastmodifieddate: savedFilters?.hs_lastmodifieddate ? savedFilters?.hs_lastmodifieddate : [],
                createdate: savedFilters?.createdate ? savedFilters?.createdate : [],
                global_search: ""
            }
            let { global_search, ...saveFilter } = searchParams
            saveFilterToLocal('hscompany', saveFilter)
            setFilters(searchParams)
            getHSCompaniesPagination(paginationPerPage, 1, searchParams, filters.sorter, true)
        }
    }, [globalSearch])

    useEffect(() => {
        const handleIntersection = (entries) => {
            if (entries[0].isIntersecting) {
                if (companyPaging?.next?.after) {
                    getHSCompaniesPaginationFetchNext(paginationPerPage, 1, filters, filters.sorter, true, companyPaging.next?.after);
                }
            }
        };

        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

        const lastRowElement = document.getElementById('last-company-row');
        if (lastRowElement) {
            observer.observe(lastRowElement);
        }

        return () => {
            if (lastRowElement) {
                observer.unobserve(lastRowElement);
            }
        };
    }, [companyPaging]);

    const sendAlert = ({ type, content, className }) => {
        if (content) {
            messageApi.open({
                type: type || 'success',
                content: content,
                className: `alert-message ${className ? className : ''}`,
            });
        }
    }

    return (
        <>
        {contextHolder}
        <div className="PageContent">
            <div className='mx-3 mt-2 settingPage password-page hubspot-table-page'>
                <div className="header px-3 py-1 d-flex justify-content-between">
                    <div><span className='pe-2'>
                        <FaBuilding />
                    </span>
                        Company </div>
                    <div className='d-flex action-btn-wrap'>
                        {selectedRows?.length > 0 && <div className='action-btn'>
                            <p>{selectedRows?.length} Selected</p>
                            <p className='action' onClick={deleteBatchCompanyHandler}><RiDeleteBin6Line />Delete</p>
                        </div>}
                        <p>
                            <input
                                type="checkbox"
                                onChange={onMyContactClick}
                                checked={isMyCompany}
                            /> My Company
                        </p>
                        <CreateCompanyButton onClose={onCompanyCreateClose} />
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={companyList}
                    sticky={{
                        offsetHeader: 0,
                    }}
                    rowKey={'id'}
                    onChange={onTableChangeHandler}
                    pagination={false}
                    rowSelection={getRowSelectionProps()}
                    onRow={(record, rowIndex) => ({
                        id: rowIndex === companyList.length - 1 && companyPaging?.next?.after ? "last-company-row" : ''
                        //     onClick: (e) => { onRowClick(e, record) }
                    })}
                    loading={{
                        indicator: <LoadingOutlined style={{ fontSize: 50, color: '#2c0036', }} spin />,
                        spinning: isLoading
                    }}
                />
            </div>
            {showDrawer && <CompanyOffCanvas
                record={selectedCompany}
                show={showDrawer}
                setShow={setShowDrawer}
                afterDelete={afterDeleteClickPreview}
                onClose={onCompanyPreviewClose}
                viewRecord={viewRecordButtonHandler}
                openFileUpload={openPhotoUploadModal}
                openFileRemove={openFileRemoveHandler}
                sendMessage={sendAlert}
                removeContactAssociation={handleContactAssocoationRemove}
            />}
            {<DeleteConfirmModal />}
            <ModalComponent onConfirm={handlePhotoUpload} />
        </div>
        </>
    )
}

export default HubspotCompanies