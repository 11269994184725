/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import * as FaIcons from "react-icons/fa6";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import '../../SCSS/popups.scss';
import { useFormik } from 'formik';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { hsCompanySchema } from '../../../Utils/validation';
import { addHSCompany } from '../../../API/authCurd';
import { useCompanyType, useCompanyOwners, useCompanyIndustry, useCompanyProps } from '../../hooks/company';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import SearchDropdown from './SearchDropdown';
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { amountCheck } from '../../../Utils/helpers';
import { useNavigate } from 'react-router-dom';
import MyTooltip from './MyTooltip';
import MultiselectDropdown from './MultiselectDropdown';
import { useContactList } from '../../hooks/contacts';
import { useDealList } from '../../hooks/deals';

const initialValues = {
  name: '',
  domain: '',
  // hubspot_owner_id: '',
  // industry: '',
  type: '',
  city: '',
  state: '',
  // zip: '',
  // numberofemployees: '',
  // annualrevenue: '',
  // timezone: '',
  // description: '',
  // linkedin_company_page: '',
  hs_analytics_source: '',
  associated_deals: [],
  associated_contacts: []
}

const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,7}$/;

function CreateContactButton({ onClose }) {
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false)
  const { companyOwners, getCompanyOwners } = useCompanyOwners();
  const { companyType, getCompanyType } = useCompanyType();
  const { companyIndustry, getCompanyIndustry } = useCompanyIndustry();
  const { companyOriginalSource, getCompanyProps } = useCompanyProps();
  const [areFieldsEnabled, setAreFieldsEnabled] = useState(false);
  const { userData } = useContext(GlobalSearch)
  const navigate = useNavigate()
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const { contacts, getContacts } = useContactList();
  const { deals, getDeals } = useDealList();

  const { errors, resetForm, getFieldProps, handleSubmit, touched, values, setFieldValue } = useFormik({
    initialValues,
    validationSchema: hsCompanySchema,
    onSubmit: (values) => {
      const data = { ...values }
      // if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      setIsSaving(true)
      let formattedData = {
        companyData: {
          name: data?.name || "",
          domain: data?.domain || "",
          type: data?.type || "",
          city: data?.city || "",
          state: data?.state || "",
          hs_analytics_source: data?.hs_analytics_source || "",
        },
        contactIds: {
          id: data?.associated_contacts ? data?.associated_contacts : [],
          type: "company_to_contact"
        },
        dealIds: {
          id: data?.associated_deals ? data?.associated_deals : [],
          type: "company_to_deal"
        }
      }
      addHSCompany(formattedData).then((res) => {
        if (res.data?.id) {
          navigate(`/hubspot/companies/${res.data.id}`)
        } else {
          onClose(true)
        }
        closeButtonHandler()
      }).catch((err) => {
      }).finally(() => { setIsSaving(false) })
    },
  });

  function showButtonHandler() {
    // getCompanyOwners()
    getCompanyType()
    // getCompanyIndustry()
    getContacts()
    getDeals()
    getCompanyProps()
    setShow(true)
  }

  function closeButtonHandler() {
    setShow(false)
    setIsSaving(false)
    resetForm()
    onClose()
  }

  useEffect(() => {
    const isDomainValid = domainRegex.test(values.domain);
    const isPrimaryFieldValid = isDomainValid || values.name;

    setAreFieldsEnabled(!!isPrimaryFieldValid);
  }, [values.domain, values.name]);

  useEffect(() => {
    const isDomainValid = domainRegex.test(values.domain);

    const isNameValid = values.name.trim() !== '';
    const isDomainValidOrEmpty = values.domain.trim() === '' || isDomainValid;

    setIsSaveEnabled((isNameValid || isDomainValid) && isDomainValidOrEmpty);
  }, [values.domain, values.name]);

  // useEffect(() => {
  //   if (companyOwners) {
  //     let currentUser = companyOwners?.find((item) => item.email === userData.email);
  //     if (currentUser) { setFieldValue('hubspot_owner_id', currentUser?.id) }
  //   }
  // }, [companyOwners, userData])

  return (
    <>
      <Button className="headBtn" onClick={showButtonHandler}>
        <FaIcons.FaPlus />  Create Company
      </Button>

      <Offcanvas placement='end' show={show} onHide={closeButtonHandler}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Company</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='form-wrap'>
            <div>
              <FloatingLabel label="Company Domain Name">
                <Form.Control
                  type="text"
                  name='domain'
                  placeholder=''
                  {...getFieldProps("domain")}
                />
              </FloatingLabel>
              {(touched.domain && errors.domain) ? <span className='ms-2 text-danger'>{errors.domain}</span> : null}
            </div>
            <div>
              <FloatingLabel label="Company Name">
                <Form.Control
                  type="text"
                  name='name'
                  placeholder=''
                  {...getFieldProps("name")}
                />
              </FloatingLabel>
              {(touched.name && errors.name) ? <span className='ms-2 text-danger'>{errors.name}</span> : null}
            </div>
            {/* <SearchDropdown
              label="Company Owner"
              options={[
                { label: "No Owner", value: 'default' },
                ...sortByConcatenatedString(companyOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                  value: id,
                  label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                }))]}
              placeholder="Choose..."
              onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
              initialValue={values?.hubspot_owner_id ?? "default"}
              disabled={!areFieldsEnabled}
            /> */}
            {/* <SearchDropdown
              label="Industry"
              options={sortObjectsByAttribute(companyIndustry, 'displayOrder')}
              placeholder="Choose..."
              onSelect={(value) => setFieldValue('industry', value)}
              initialValue={values?.industry ?? "default"}
              disabled={!areFieldsEnabled}
            /> */}
            <SearchDropdown
              label="Type"
              options={sortObjectsByAttribute(companyType, 'displayOrder')}
              placeholder="Choose..."
              onSelect={(value) => setFieldValue('type', value)}
              initialValue={values?.type ?? "default"}
              disabled={!areFieldsEnabled}
            />
            <div>
              <FloatingLabel label="City">
                <Form.Control
                  type="text"
                  name='city'
                  placeholder=''
                  {...getFieldProps("city")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.city && errors.city) ? <span className='ms-2 text-danger'>{errors.city}</span> : null}
            </div>
            <div>
              <FloatingLabel label="State/Region">
                <Form.Control
                  type="text"
                  name='state'
                  placeholder=''
                  {...getFieldProps("state")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.state && errors.state) ? <span className='ms-2 text-danger'>{errors.state}</span> : null}
            </div>
            {/* <div>
              <FloatingLabel label="Postal Code">
                <Form.Control
                  type="text"
                  name='zip'
                  placeholder=''
                  {...getFieldProps("zip")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.zip && errors.zip) ? <span className='ms-2 text-danger'>{errors.zip}</span> : null}
            </div> */}
            {/* <div>
              <FloatingLabel label="Number Of Employees">
                <Form.Control
                  type="text"
                  placeholder=''
                  name='numberofemployees'
                  {...getFieldProps("numberofemployees")}
                  disabled={!areFieldsEnabled}
                  onKeyDown={(e) => amountCheck(e)}
                />
              </FloatingLabel>
              {(touched.numberofemployees && errors.numberofemployees) ? <span className='ms-2 text-danger'>{errors.numberofemployees}</span> : null}
            </div> */}
            {/* <div>
              <FloatingLabel label="Annual Revenue">
                <Form.Control
                  type="text"
                  placeholder=''
                  name='annualrevenue'
                  {...getFieldProps("annualrevenue")}
                  onKeyDown={(e) => amountCheck(e)}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.annualrevenue && errors.annualrevenue) ? <span className='ms-2 text-danger'>{errors.annualrevenue}</span> : null}
            </div> */}
            {/* <div>
              <FloatingLabel label="Time Zone">
                <Form.Control
                  type="text"
                  placeholder=''
                  name='timezone'
                  {...getFieldProps("timezone")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.timezone && errors.timezone) ? <span className='ms-2 text-danger'>{errors.timezone}</span> : null}
            </div> */}
            {/* <div>
              <FloatingLabel label="Description">
                <Form.Control
                  type="text"
                  name='description'
                  placeholder=''
                  {...getFieldProps("description")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.description && errors.description) ? <span className='ms-2 text-danger'>{errors.description}</span> : null}
            </div> */}
            {/* <div>
              <FloatingLabel label="LinkedIn Company Page">
                <Form.Control
                  type="text"
                  placeholder=''
                  name='linkedin_company_page'
                  {...getFieldProps("linkedin_company_page")}
                  disabled={!areFieldsEnabled}
                />
              </FloatingLabel>
              {(touched.linkedin_company_page && errors.linkedin_company_page) ? <span className='ms-2 text-danger'>{errors.linkedin_company_page}</span> : null}
            </div> */}
            <SearchDropdown
              label="Original source"
              options={sortObjectsByAttribute(companyOriginalSource, 'displayOrder')}
              placeholder="Choose..."
              onSelect={(value) => setFieldValue('hs_analytics_source', value)}
              initialValue={values?.hs_analytics_source ?? ""}
              disabled={!areFieldsEnabled}
            />
            {/* Company Associations */}
            <div className='border-bottom'></div>

            <h5>Associate company with</h5>

            <div>
              <MultiselectDropdown
                label="Deal"
                options={sortObjectsByAttribute(deals ? deals : [], 'label')}
                onChange={(value) => { setFieldValue('associated_deals', value) }}
                value={values.associated_deals}
                disabled={!areFieldsEnabled}
              />
            </div>
            <div>
              <MultiselectDropdown
                label="Contact"
                options={sortObjectsByAttribute(contacts ? contacts : [], 'label')}
                onChange={(value) => { setFieldValue('associated_contacts', value) }}
                value={values.associated_contacts}
                disabled={!areFieldsEnabled}
              />
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
              {values?.domain && !domainRegex.test(values?.domain) ? <MyTooltip title={'Please update invalid property values.'}>
                <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
              </MyTooltip> :
                <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
              }
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CreateContactButton;