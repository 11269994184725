/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { LoadingOutlined } from '@ant-design/icons';
import { FaDownload } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { downloadFile } from '../../../Utils/helpers';
import { Spin, Tooltip } from 'antd';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import axios from 'axios';

const DocumentViewer = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');
    const url = queryParams.get('url');
    const [fileSrc, setFileSrc]= useState([])

    const fileExtList = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'odt', 'htm', 'html', 'txt', 'mp4'];

    const fileContentTypeList = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'webp': 'image/webp',
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'ppt': 'application/vnd.ms-powerpoint',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'odt': 'application/vnd.oasis.opendocument.text',
        'htm': 'text/html',
        'html': 'text/html',
        'txt': 'text/plain',
        'mp4': 'video/mp4'
    }

    useEffect(() => {
        const fetchFile = async () => {
            if (!fileExtList.some(ext => url.toLowerCase().endsWith(`.${ext}`))) {
                try {
                    const response = await axios({
                        url,
                        method: 'GET',
                        responseType: 'blob',
                    });

                    const urlParts = name.split('.');
                    const fileExt = urlParts[urlParts.length - 1].toLowerCase();
                    const inferredContentType = fileContentTypeList[fileExt] || 'application/octet-stream';

                    if (response?.data) {
                        const blob = new Blob([response?.data], { type: inferredContentType });
                        const blobUrl = URL.createObjectURL(blob);
                        setFileSrc([{ uri: blobUrl }]);
                    } else {
                        setFileSrc([{ uri: url }]);
                    }
                } catch (error) {
                    console.error('Error fetching the file:', error);
                    setFileSrc([{ uri: url }]);
                }
            } else {
                setFileSrc([{ uri: url }]);
            }
        };
        // fetchFile();
    }, [url, name]);

    const handleDownload = (fileUrl, fileName) => {
        downloadFile(fileUrl, fileName)
    }

    const docs = [{ uri: url }];

    return (
        <div className='document-viewer' style={{ height: '100vh', width: '100vw' }}>
            <div className='document-header'>
                <span>{name}</span>
                <div className='d-flex align-items-center justify-content-between'>
                    <Tooltip title={"Download"}>
                        <span className="custom-icon" onClick={() => handleDownload(url, name)}>
                            <FaDownload />
                        </span>
                    </Tooltip>
                </div>
            </div>
            {name.split('.').pop().toLowerCase() == 'pdf' ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={url}
                    renderLoader={() => (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#000' }} spin />} />
                    )}
                />
            </Worker> : <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                    },
                    pdfZoom: {
                        defaultZoom: 0.25,
                        zoomJump: 0.2,
                    },
                    loadingRenderer: {
                        overrideComponent: () => (
                            <div className='w-100 d-flex align-items-center justify-content-center'>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#000' }} spin />} />
                            </div>
                        ),
                        showLoadingTimeout: false
                    },
                }}
            />}
        </div>
    );
};

export default DocumentViewer;
