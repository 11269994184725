import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { IoIosCloseCircle } from "react-icons/io";
import SaveReport from '../commonModules/UI/SaveReport';

const deals = [
    { dealName: 'Alpha Corp Partnership', createdAt: '2024-08-01', amount: 1200 },
    { dealName: 'Beta Tech Solutions', createdAt: '2024-07-15', amount: 850 },
    { dealName: 'Gamma Services Agreement', createdAt: '2024-08-22', amount: 1500 },
    { dealName: 'Delta Industries Contract', createdAt: '2024-06-30', amount: 2300 },
    { dealName: 'Omega Enterprises Deal', createdAt: '2024-07-29', amount: 4000 },
    { dealName: 'Zeta Solutions Package', createdAt: '2024-08-10', amount: 950 },
    { dealName: 'Epsilon Network Expansion', createdAt: '2024-08-18', amount: 1800 },
    { dealName: 'Theta Corporation Agreement', createdAt: '2024-07-05', amount: 1300 },
    { dealName: 'Sigma Software Subscription', createdAt: '2024-08-20', amount: 500 },
]

const Modal = ({ show, columns, dataSource, isLoading, modalHeading, onCancel }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>{modalHeading}</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='action-wrap'>
                        <button className="button">Use in list</button>
                        <button className="button">Export</button>
                        {/* <button className="button">Save as report</button> */}
                        <SaveReport/>
                    </div>
                    <div className='popBody p-3 customer-body'>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{ y: `calc(100vh - 300px)` }}
                            sticky={{
                                offsetHeader: 0,
                            }}
                            loading={{
                                indicator: <LoadingOutlined style={{ fontSize: 50, color: '#2c0036', }} spin />,
                                spinning: isLoading
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>)
}

const useTotalDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openDealTotalModal = () => {
        setOpen(true)
    }

    const closeDealTotalModal = () => {
        setOpen(false)
    }

    const TotalDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeDealTotalModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, TotalDealModal, openDealTotalModal, closeDealTotalModal }
}

const useWeightedDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Deal Stage Weighted Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openWeightedDealModal = () => {
        setOpen(true)
    }

    const closeWeightedDealModal = () => {
        setOpen(false)
    }

    const WeightedDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeWeightedDealModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, WeightedDealModal, openWeightedDealModal, closeWeightedDealModal }
}

const useOpenDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Deal Stage Weighted Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openOpenDealModal = () => {
        setOpen(true)
    }

    const closeOpenDealModal = () => {
        setOpen(false)
    }

    const OpenDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeOpenDealModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, OpenDealModal, openOpenDealModal, closeOpenDealModal }
}

const useCloseDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Deal Stage Weighted Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openCloseDealModal = () => {
        setOpen(true)
    }

    const closeCloseDealModal = () => {
        setOpen(false)
    }

    const CloseDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeCloseDealModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, CloseDealModal, openCloseDealModal, closeCloseDealModal }
}

const useNewDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Deal Stage Weighted Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openNewDealModal = () => {
        setOpen(true)
    }

    const closeNewDealModal = () => {
        setOpen(false)
    }

    const NewDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeNewDealModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, NewDealModal, openNewDealModal, closeNewDealModal }
}

const useAverageDeal = () => {
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const columnDef = [
            {
                title: 'Deal ',
                dataIndex: 'dealName',
                key: 'dealName',
            },
            {
                title: 'Create Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
            },
            {
                title: 'Deal Stage Weighted Amount ',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        setColumns(columnDef)
    }, [])

    const openAverageDealModal = () => {
        setOpen(true)
    }

    const closeAverageDealModal = () => {
        setOpen(false)
    }

    const AverageDealModal = () => (
        <Modal
            show={open}
            columns={columns}
            dataSource={deals}
            isLoading={isLoading}
            modalHeading={'Report Details'}
            onCancel={closeAverageDealModal}
        />
    );

    return { columns, setColumns, isLoading, setIsLoading, open, setOpen, AverageDealModal, openAverageDealModal, closeAverageDealModal }
}

export { useTotalDeal, useWeightedDeal, useOpenDeal, useCloseDeal, useNewDeal, useAverageDeal }