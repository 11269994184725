/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useEffect, useState } from 'react';

import SelectDropDown from './SelectDropDown';
import { sortObjectsByAttribute } from '../../Utils/sortFunctions';

export default function PasswordCustomerFilter({ clearFilters, close, filters, setFilters, onFilter, onReset, allOptions }) {
    const [searchOptions, setSearchOptions] = useState([])
    const [searchText, setSearchText] = useState("")
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (allOptions) {
            let newArr = allOptions?.map((item) => ({ value: item.id, label: item.name, id: item.id }))
            setSearchOptions(sortObjectsByAttribute(newArr, "label"))
        } else {
            setSearchOptions([])
        }
    }, [allOptions])

    const onSelect = (option) => {
        close()
        onFilter({ key: "customer_id", id: option?.id })
    };

    const onClear = () => {
        setFilters((prev) => ({ ...prev, customer_id: [] }))
        onResetBtn()
    };

    const onResetBtn = () => {
        onReset('customer_id')
        setFilters((prev) => ({ ...prev, customer_id: [] }))
        close()
        clearFilters && clearFilters()
    }

    useEffect(() => {
        if (filters?.customer_id?.length > 0 && searchOptions?.length > 0) {
            let customerId = filters.customer_id[0];
            setValue(typeof jobId === 'string' ? Number(customerId) : customerId);
        } else {
            setValue(null);
        }
    }, [filters?.customer_id, searchOptions]);

    return (
        <SelectDropDown
            onSelect={onSelect}
            onClear={onClear}
            onReset={onResetBtn}
            options={searchOptions}
            placeholder={"Select a customer"}
            searchText={searchText}
            setSearchText={setSearchText}
            value={value}
            setValue={setValue}
        />
    )
}
