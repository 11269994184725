/* eslint-disable eqeqeq */
import { Button, Popover, Tag, Tooltip } from 'antd'
import React, { useState } from 'react'
import { IoCopy } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';

const CompanyCard = ({ companyData, primaryKey, handleRemove, handleSetPrimary, section }) => {
    const [copyText, setCopyText] = useState("Copy to clipboard")
    const [copyPhoneText, setCopyPhoneText] = useState("Copy to clipboard")
    const navigate = useNavigate()


    const getNameOrDomain = (data) => {
        let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
        return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
    }

    const handleCopy = (data) => {
        if (data?.properties?.domain) {
            navigator.clipboard.writeText(data.properties.domain).then(() => {
                setCopyText('Copied');
                setTimeout(() => {
                    setCopyText('Copy to clipboard');
                }, 2000)
            }).catch(err => { });
        }
    };

    const handlePhoneCopy = (data) => {
        if (data?.properties?.phone) {
            navigator.clipboard.writeText(data.properties.phone).then(() => {
                setCopyPhoneText('Copied');
                setTimeout(() => {
                    setCopyPhoneText('Copy to clipboard');
                }, 2000)
            }).catch(err => { });
        }
    };

    const navigateTo = (e, compId) => {
        if (compId) {
            navigate(`/hubspot/companies/${compId}`)
        }
    }

    function urlRedirect(domain) {
        if (domain) {
            window.open(`https://${domain}`, '_blank');
        }
    };

    const popoverContent = (
        <div className='d-flex flex-column'>
            {(primaryKey != companyData?.id && section == 'contacts') && <Button className='me-2 mb-2' onClick={(e) => { handleSetPrimary(e, companyData, primaryKey) }}>Set as primary</Button>}
            <Button className='me-2' danger onClick={(e) => { handleRemove(e, companyData, primaryKey) }}>Remove association</Button>
        </div>
    );

    return (
        <div className='association-card'>
            {primaryKey == companyData?.id && <Tag className='primary-tag' >Primary</Tag>}
            <Popover
                placement='bottom'
                content={popoverContent}
                trigger="click"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
                <span className='more-button'>More</span>
            </Popover>
            <p className='association-name' onClick={(e) => { navigateTo(e, companyData?.id) }}>{getNameOrDomain(companyData)}</p>
            <p className='association-email domain'>{companyData?.properties.domain ? <span className='link' onClick={() => urlRedirect(companyData?.properties?.domain)}>{companyData?.properties.domain} {<FiExternalLink className={`ms-2`} />}</span> : 'Domain: --'} {companyData?.properties.domain && <Tooltip placement="top" title={copyText}>
                <IoCopy className={`ms-2`} onClick={() => handleCopy(companyData)} />
            </Tooltip>}</p>
            {companyData?.properties.phone && <p className='association-phone'>{companyData?.properties.phone} <Tooltip placement="top" title={copyPhoneText}>
                <IoCopy className={`ms-2`} onClick={() => handlePhoneCopy(companyData)} />
            </Tooltip></p>}
        </div>
    )
}

export default CompanyCard