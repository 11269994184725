/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import '../../SCSS/popups.scss';
import AccordionNote from '../../commonModules/UI/AccordianNote';
import { IoCopy } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoChevronBackSharp } from "react-icons/io5";
import CreateNoteModal from '../../commonModules/UI/CreateNoteModal';
import { useContactOwners, useContactProps } from '../../hooks/contacts';
import { deleteHSContact, deleteHSNotes, getSingleHSContact, updateHSContact } from '../../../API/authCurd';
import { Tooltip, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfirm } from '../../hooks/use-confirm';
import { useFormik } from 'formik';
import { hsContactUpdateSchema } from '../../../Utils/validation';
import { useHsNotes, useSetPrimary } from '../../hooks/hubspot';
import { parseDateTimeString } from '../../../Utils/dateFormat';
import { hsObjectSource } from '../../../Utils/staticdata';
import { constructHubSpotFileUrl, deletePropertiesIfExists, extractIds, getAvatarInitials, getNameInitialWithemail, sendEmail } from '../../../Utils/helpers';
import { useUploadPhotoModal } from '../../hooks/uploadPhoto';
import EditableAvatar from '../../commonModules/UI/EditableAvatar';
import { FaRegEdit } from "react-icons/fa";
import EditTooltip from '../../commonModules/UI/EditTooltip';
import PhoneNumberPopover from '../../commonModules/UI/PhoneNumberPopover';
import SearchDropdown from '../../commonModules/UI/SearchDropdown';
import MultiselectDropdown from '../../commonModules/UI/MultiselectDropdown';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import { useDeleteContact } from '../../hooks/useDeleteContact';
import ContactAssociation from '../../commonModules/UI/associationForms/contact/ContactAssociation';
import CompanyAssociation from '../../commonModules/UI/associationForms/company/CompanyAssociation';
import DealAssociation from '../../commonModules/UI/associationForms/deals/DealAssociation';

const initialValues = {
  email: '',
  hubspot_owner_id: '',
  phone: '',
  lifecyclestage: '',
  hs_lead_status: '',
  hs_object_source: '',
  hs_legal_basis: null
}

const HubspotContactDetails = () => {
  const { id } = useParams();
  const [openCreateNote, setOpenCreateNote] = useState(false);
  const [isSaving, setIsSaving] = useState(false)
  const [copyText, setCopyText] = useState("Copy email to clipboard")
  const { confirmDeleteWindow, DeleteConfirmModal } = useConfirm();
  const { contactOwners, getContactOwners } = useContactOwners();
  const { notes, setNotes, getNotes, notesPaging, fetchNextNotes } = useHsNotes();
  const [contactData, setContactData] = useState(null)
  const navigate = useNavigate()
  const { openModal, ModalComponent } = useUploadPhotoModal();
  const [tagList, setTagList] = useState([])
  const [notesLoading, setNotesLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const { getContactProps, contactLegalBasis, contactLifecycles, contactLeadStatus } = useContactProps();
  const { DeleteHSConfirmModal, confirmDelete } = useDeleteContact();
  const [messageApi, contextHolder] = message.useMessage();
  const { confirmSetPrimaryWindow, SetPrimaryConfirmModal } = useSetPrimary();

  const [contactAssociations, setContactAssociations] = useState([])
  const [companyAssociations, setCompanyAssociations] = useState([])
  const [dealAssociations, setDealAssociations] = useState([])

  const { errors, resetForm, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema: hsContactUpdateSchema,
    onSubmit: (values) => {
      let data = { ...values }
      data.hs_legal_basis = values?.hs_legal_basis?.join(';')
      deletePropertiesIfExists(data, ["hs_object_source"])
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      setIsSaving(true)
      updateHSContact(id, data).then((res) => {
        getSingleContactData(id)
        sendAlert({ content: 'Changes saved' })
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  function getSingleContactData(id, loader = false) {
    if (loader) {
      setContactLoading(true)
    }
    getSingleHSContact(id).then((res) => {
      if (res?.data?.contacts?.[0]) {
        setCompanyAssociations(res?.data?.associations?.companies ? res?.data?.associations?.companies : [])
        setContactAssociations(res?.data?.associations?.associatedContacts ? res?.data?.associations?.associatedContacts : [])
        setDealAssociations(res?.data?.associations?.deals ? res?.data?.associations?.deals : [])
        setContactData(res?.data?.contacts?.[0])
      }
    }).catch(() => { setContactData(null) })
      .finally(() => { setContactLoading(false) })
  }

  useEffect(() => {
    if (id) {
      getSingleContactData(id, true)
      getContactOwners()
      getContactProps()
      setNotesLoading(true)
      getNotes('contact', id).finally(() => { setNotesLoading(false) })
    }
  }, [id])

  useEffect(() => {
    if (contactOwners) {
      let users = contactOwners?.map((item) => ({
        id: item.id,
        value: `${item?.firstName ? item?.firstName : ""} ${item?.lastName ? item?.lastName : ""}`,
      }))
      setTagList(users)
    } else {
      setTagList([])
    }
  }, [contactOwners])

  useEffect(() => {
    if (contactData) {
      Object.keys(initialValues).forEach((field) => {
        if (field == 'hs_legal_basis') {
          let vals = contactData?.properties?.[field]?.split(';')
          setFieldValue(field, vals)
        } else if (field == 'hubspot_owner_id') {
          setFieldValue(field, contactData?.properties?.[field] ? contactData?.properties?.[field] : "default")
        } else {
          setFieldValue(field, contactData?.properties?.[field] ? contactData?.properties?.[field] : "")
        }
      })
    }
  }, [contactData, setFieldValue])

  const handleCopy = (data) => {
    if (data?.properties?.email) {
      navigator.clipboard.writeText(data.properties.email).then(() => {
        setCopyText('Copied');
        setTimeout(() => {
          setCopyText('Copy email to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };

  const getName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  // delete button handler
  const deleteContactHandler = async (e, data) => {
    let name = getName(data)
    let msg = `You are about to delete the contact ${name}. Contact data is treated differently when you perform a restorable delete versus a permanent delete.`
    let confirm = await confirmDelete(msg)
    if (confirm && confirm?.confirm) {
      if (data) {
        deleteHSContact(data?.id, confirm?.deleteType, confirm?.sendEmail).then(() => {
          navigate("/hubspot/contacts")
        }).catch((err) => { })
      }
    }
  }

  const onCreateNoteButtonClick = () => {
    setOpenCreateNote(true)
  }

  const onCreateNoteCancelButtonClick = (load = false, item) => {
    if (item?.data?.notes) {
      setNotes((prev) => [item?.data?.notes, ...prev])
    }
    // if (load) {
    //   getNotes('contact', id);
    // }
    setOpenCreateNote(false)
  }

  const fetchNotes = (load = false) => {
    if (load) {
      getNotes('contact', id);
    }
    setOpenCreateNote(false)
  }

  const fetchNext = async (afterId) => {
    if (afterId) {
      return fetchNextNotes(afterId, 'contact', id)
    }
  }

  const deleteNoteButtonHandler = async (deleteId) => {
    let msg = `Are you sure you want to delete this note?`
    let isConfirm = await confirmDeleteWindow(msg)
    if (isConfirm) {
      if (deleteId) {
        deleteHSNotes(deleteId).then(() => {
          getNotes('contact', id);
        }).catch((err) => { })
      }
    }
  }

  const handlePhotoUpload = (file) => { };

  const openPhotoUploadModal = (record) => {
    let initials = getNameInitialWithemail(record?.properties?.firstname, record?.properties?.lastname, record?.properties?.email)
    openModal({ initials })
  }

  const openFileRemoveHandler = (record) => { }

  const handleUpload = () => {
    openPhotoUploadModal(contactData)
  };

  const handleRemove = () => {
    openFileRemoveHandler(contactData)
  };

  const onUpdateHandler = (load) => {
    if (load) {
      getSingleContactData(id)
    }
  }

  const onPhoneApplyClick = (val, isFormatted) => {
    setFieldValue('phone', val)
    setFieldValue('is_phone_formatted', isFormatted)
  }

  const onBackArrowClick = (e) => {
    e.stopPropagation()
    navigate(`/hubspot/contacts`)
  }

  const sendAlert = ({ type, content, className }) => {
    if (content) {
      messageApi.open({
        type: type || 'success',
        content: content,
        className: `alert-message ${className ? className : ''}`,
      });
    }
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getName(data)} will no longer be associated with ${getName(contactData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getName(contactData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const getDealName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }

  const dealAssociationRemove = async (e, data) => {
    let msg = `${getDealName(data)} will no longer be associated with ${getName(contactData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const removeHandler = async (msg, heading) => {
    return await confirmDeleteWindow(msg, heading)
  }

  const handleSetPrimary = async (e, data, pk, type, section, associate_to, contactData) => {
    let heading = `Set ${getNameOrDomain(data)} as primary`
    let msg = `Replace ${contactData?.properties?.associatedcompanyid ? contactData?.properties?.primary_company_name : ''} as primary`
    return await confirmSetPrimaryWindow(msg, heading)
  }

  return (
    <>
    {contextHolder}
      <div style={{ paddingTop: '60px', background: '#F5F8FA', width: '100%' }}>
        <div className=''>
          <div className='column'>
            <div className='personal-info-col'>
              <div className='back-arrow'>
                <IoChevronBackSharp onClick={onBackArrowClick} />
                <NavLink to={'/hubspot/contacts'}>Contacts</NavLink>
              </div>
              {contactLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
                <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
              </div> : <><div className='personal-info-wrap'>
                <EditableAvatar
                  // initials={getNameInitialWithemail(contactData?.properties?.firstname, contactData?.properties?.lastname, contactData?.properties?.email)}
                  initials={getAvatarInitials(contactData?.properties?.firstname, contactData?.properties?.lastname, contactData?.properties?.email)}
                  onUpload={handleUpload}
                  onRemove={handleRemove}
                  imgUrl={contactData?.properties?.hs_avatar_filemanager_key ? constructHubSpotFileUrl(contactData?.properties?.hs_avatar_filemanager_key) : null}
                />

                <div className='edit-details-wrap'>
                  <div className='personal-info'>
                    <h6>
                      {getName(contactData)}
                    </h6>
                    {contactData?.properties?.jobtitle && <p>
                      {contactData?.properties?.jobtitle}
                    </p>}
                    {contactData?.properties?.email && <p>
                      {contactData?.properties?.email}
                      <Tooltip placement="top" title={copyText}>
                        <IoCopy className={`ms-2`} onClick={() => handleCopy(contactData)} />
                      </Tooltip>
                      <Tooltip placement="top" title={'Email'}>
                        <MdOutlineMail className={`ms-2`} onClick={() => sendEmail(contactData?.properties?.email)} />
                      </Tooltip>
                    </p>}
                  </div>
                  <EditTooltip record={contactData} id={id} onUpdate={onUpdateHandler} />
                </div>
              </div>
                <div className='main-btn-wrap'>
                  <div className='delete-btn button-wrap' onClick={onCreateNoteButtonClick}>
                    <div className='icon'> <FaRegEdit /></div>
                    <p>Note</p>
                  </div>
                  <div className='delete-btn button-wrap' onClick={(e) => deleteContactHandler(e, contactData)}>
                    <div className='icon'> <RiDeleteBin6Line /></div>
                    <p>Delete</p>
                  </div>
                </div></>}
              <div className='form-wrap'>
                <h5>About this contact</h5>
                <div>
                  <FloatingLabel label="Email">
                    <Form.Control
                      type="text"
                      name='email'
                      {...getFieldProps("email")}
                    />
                  </FloatingLabel>
                  {(touched.email && errors.email) ? <span className='ms-2 text-danger'>{errors.email}</span> : null}
                </div>
                <div>
                  <PhoneNumberPopover
                    type="text"
                    name='phone'
                    onApplyClick={onPhoneApplyClick}
                    value={values.phone ?? ""}
                    placeholder={'Phone Number'}
                    placement={'top'}
                    isFormatted={contactData?.properties?.is_phone_formatted == 'true' ? true : false}
                  />
                </div>
                <SearchDropdown
                  label="Contact Owner"
                  options={[
                    { label: "No Owner", value: 'default' },
                    ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                      value: id,
                      label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                    }))]}
                  placeholder="Choose..."
                  onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
                  initialValue={values?.hubspot_owner_id}
                />
                <div>
                  <FloatingLabel label='Last Contacted' className='custom-select'>
                    <Form.Control
                      type="text"
                      value={contactData?.properties?.notes_last_contacted ? parseDateTimeString(contactData?.properties?.notes_last_contacted, 16) : '--'}
                      disabled={true}
                    />
                  </FloatingLabel>
                </div>
                <SearchDropdown
                  label="Lifecycle Stage"
                  options={sortObjectsByAttribute(contactLifecycles, 'displayOrder')}
                  placeholder="Choose..."
                  onSelect={(value) => setFieldValue('lifecyclestage', value)}
                  initialValue={values?.lifecyclestage}
                />
                <SearchDropdown
                  label="Lead Status"
                  options={sortObjectsByAttribute(contactLeadStatus, 'displayOrder')}
                  placeholder="Choose..."
                  onSelect={(value) => setFieldValue('hs_lead_status', value)}
                  initialValue={values?.hs_lead_status}
                />
                <MultiselectDropdown
                  label="Legal Basis For Processing Contact's Data"
                  options={sortObjectsByAttribute(contactLegalBasis ? contactLegalBasis : [], 'displayOrder')}
                  onChange={(value) => { setFieldValue('hs_legal_basis', value) }}
                  value={values.hs_legal_basis}
                />
                <div>
                  <FloatingLabel label='Record Source' className='custom-select'>
                    <Form.Select
                      aria-label="Record Source"
                      name='hs_object_source'
                      {...getFieldProps("hs_object_source")}
                      disabled={true}
                    >
                      <option value="" key="0">--</option>
                      {hsObjectSource?.map((item) => {
                        return <option value={item.id} key={item.id}>{item.name}</option>
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </div>
              </div>
            </div>
            <div className='note-overview-col'>
              <div className='note-header-wrap'>
                <h5>Overview</h5>
                <div className='note-head'>
                  <p>Notes</p>
                  <Button className="headBtn" onClick={onCreateNoteButtonClick}> <FaIcons.FaPlus />Create Note</Button>
                </div>
                {/* <p className='note-month'>August 2024</p> */}
              </div>
              <AccordionNote loading={notesLoading} tagList={tagList} items={notes} owners={contactOwners} paging={notesPaging} fetchNext={fetchNext} fetchNotes={fetchNotes} onDelete={deleteNoteButtonHandler} type={'contact'} associatedObjectId={id} />
            </div>
            <div className='association-overview-col'>
              <div className='association-wrapper'>
                <ContactAssociation
                  id={contactData?.id}
                  section={'contacts'}
                  type={'contact_to_contact'}
                  associate_to={'contacts'}
                  associatedContacts={contactAssociations ? contactAssociations : []}
                  associatedContactIds={extractIds(contactAssociations ? contactAssociations : [])}
                  fetchData={getSingleContactData}
                  sendMessage={sendAlert}
                  handleRemove={contactAssociationRemove}
                />
                <div className='border-bottom'></div>
                <CompanyAssociation
                  id={contactData?.id}
                  section={'contacts'}
                  type={'contact_to_company'}
                  associate_to={'companies'}
                  associatedCompanies={companyAssociations ? companyAssociations : []}
                  associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
                  fetchData={getSingleContactData}
                  sendMessage={sendAlert}
                  primaryKey={contactData?.properties?.associatedcompanyid}
                  handleRemove={companyAssociationRemove}
                  handleSetPrimary={(e, data, pk, type, section, associate_to) => { return handleSetPrimary(e, data, pk, type, section, associate_to, contactData) }}
                />
                <div className='border-bottom'></div>
                <DealAssociation
                  id={contactData?.id}
                  section={'contacts'}
                  type={'contact_to_deal'}
                  associate_to={'deals'}
                  associatedDeals={dealAssociations ? dealAssociations : []}
                  associatedDealIds={extractIds(dealAssociations ? dealAssociations : [])}
                  fetchData={getSingleContactData}
                  sendMessage={sendAlert}
                  handleRemove={dealAssociationRemove}
                  primaryKey={contactData?.properties?.associatedcompanyid}
                  name={`${contactData?.properties?.primary_company_name ? contactData?.properties?.primary_company_name : ""}`.trim()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCreateNote && <CreateNoteModal tagList={tagList} onCancel={onCreateNoteCancelButtonClick} type={'contact'} associatedObjectId={id} owners={contactOwners} />}
      {<DeleteConfirmModal />}
      {<DeleteHSConfirmModal />}
      {<SetPrimaryConfirmModal />}
      <ModalComponent onConfirm={handlePhotoUpload} />
    </>
  )
}

export default HubspotContactDetails