/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Avatar, Popover, Button } from 'antd';
import { EditOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';

const EditableAvatar = ({ initials, onUpload, onRemove, imgUrl }) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleVisibleChange = (visible) => {
        setIsPopoverVisible(visible);
    };

    const handleRemove = () => {
        onRemove();
        setIsPopoverVisible(false);
    };

    const popoverContent = (
        <div className='d-flex flex-column'>
            <Button icon={<UploadOutlined />} onClick={onUpload} className='me-2'>Upload image</Button>
            <Button
                icon={<DeleteOutlined />}
                danger
                onClick={handleRemove}
                style={{ marginTop: 8 }}
            >
                Remove image
            </Button>
        </div>
    );

    return (
        <div
            style={{
                display: 'inline-block',
                position: 'relative',
                cursor: 'pointer'
            }}
            // onMouseEnter={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
        >
            <Avatar
                size={64}
                style={{ backgroundColor: '#87d068' }}
                src={imgUrl ? imgUrl : null}
            >
                {initials}
            </Avatar>
            {isHovered && (
                <EditOutlined
                    onClick={() => setIsPopoverVisible(true)}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        fontSize: '18px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: '2px',
                        color: '#1890ff',
                        zIndex: 99999999999
                    }}
                />
            )}
            <Popover
                content={popoverContent}
                trigger="click"
                visible={isPopoverVisible}
                onVisibleChange={handleVisibleChange}
                placement='bottom'
                getPopupContainer={(triggerNode) => document.body}
            />
        </div>
    );
};

export default EditableAvatar;
