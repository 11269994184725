/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from "axios"

import { getCustomerTaskList } from '../../API/authCurd';
import { sortObjectsByAttribute } from '../../Utils/sortFunctions';
import SelectDropDown from './SelectDropDown';
import { deletePropertiesIfExists } from "../../Utils/helpers"


export default function TaskFilter({ clearFilters, close, filters, setFilters, onFilter, onReset, subsection }) {
    const [searchOptions, setSearchOptions] = useState([])
    const [cancelToken, setCancelToken] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [value, setValue] = useState(null)

    // Search Api
    function getSearchOptions(customer_id, job_id, searchParams) {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.');
        }
        // Create a new CancelToken
        const newCancelToken = axios.CancelToken.source();
        setCancelToken(newCancelToken);
        return getCustomerTaskList(customer_id, job_id, newCancelToken.token, searchParams).then((res) => {
            if (res.data) { return res.data?.Tasks }
        }).catch(() => { return })
    }

    function setTasks(customer_id, job_id, search) {
        let searchParams = JSON.stringify(search ? search : {})
        getSearchOptions(customer_id, job_id, searchParams).then((data) => {
            if (data) {
                let newArr = data?.map((item) => ({ value: item.id, label: item.name, id: item.id }))
                setSearchOptions(sortObjectsByAttribute(newArr, "label"))
            }
        })
    }

    useEffect(() => {
        let fils = {...filters}
        fils['task_status'] = fils.task_status ? fils.task_status: fils.status
        fils['task_assignee'] = fils.assignee
        deletePropertiesIfExists(fils, ['status', 'assignee', 'sorter', 'customer_id', 'job_id', 'global_search', 'task_id'])
        setTasks(filters?.customer_id?.[0], filters?.job_id?.[0], fils)
    }, [filters?.customer_id, filters?.job_id, filters])


    const onSelect = (option) => {
        close()
        onFilter({ key: "task_id", id: option?.id })
    };

    const onClear = () => {
        setFilters((prev) => ({ ...prev, task_id: [] }))
        onResetBtn()
    };

    const onResetBtn = () => {
        onReset('task_id')
        setFilters((prev) => ({ ...prev, task_id: [] }))
        close()
        clearFilters && clearFilters()
    }

    useEffect(() => {
        if (filters?.task_id?.length > 0 && searchOptions?.length > 0) {
            let taskId = filters.task_id[0];
            setValue(typeof jobId === 'string' ? Number(taskId) : taskId);
        } else {
            setValue(null);
        }
    }, [filters?.task_id, searchOptions]);

    return (
        <SelectDropDown
            onSelect={onSelect}
            onClear={onClear}
            onReset={onResetBtn}
            options={searchOptions}
            placeholder={"Select a task"}
            searchText={searchText}
            setSearchText={setSearchText}
            value={value}
            setValue={setValue}
        />
    )
}
