import React from 'react'
import { Outlet } from 'react-router-dom'

const HubspotWrapper = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default HubspotWrapper