/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from "axios"

import { getCustomerDropdownList } from '../../API/authCurd';
import { sortObjectsByAttribute } from '../../Utils/sortFunctions';
import SelectDropDown from './SelectDropDown';

export default function CustomerFilter({ clearFilters, close, filters, setFilters, onFilter, onReset, subsection }) {
    const [searchOptions, setSearchOptions] = useState([])
    const [cancelToken, setCancelToken] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [value, setValue] = useState(null)

    // Search Api
    function getSearchOptions() {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.');
        }
        // Create a new CancelToken
        const newCancelToken = axios.CancelToken.source();
        setCancelToken(newCancelToken);

        return getCustomerDropdownList(newCancelToken.token, 1).then((res) => {
            if (res.data) { return res.data?.customers }
        }).catch(() => { return })
    }

    function setCustomers() {
        getSearchOptions().then((data) => {
            if (data) {
                let newArr = data?.map((item) => ({ value: item.id, label: item.name, id: item.id }))
                setSearchOptions(sortObjectsByAttribute(newArr, "label"))
            }
        }).catch(() => {
            setSearchOptions([])
        })
    }

    useEffect(() => {
        setCustomers()
    }, [])

    const onSelect = (option) => {
        close()
        onFilter({ key: "customer_id", id: option?.id })
    };

    const onClear = () => {
        setFilters((prev) => ({ ...prev, customer_id: [] }))
        onResetBtn()
    };

    const onResetBtn = () => {
        onReset('customer_id')
        setFilters((prev) => ({ ...prev, customer_id: [] }))
        close()
        clearFilters && clearFilters()
    }

    useEffect(() => {
        if (filters?.customer_id?.length > 0 && searchOptions?.length > 0) {
            let customerId = filters.customer_id[0];
            setValue(typeof jobId === 'string' ? Number(customerId) : customerId);
        } else {
            setValue(null);
        }
    }, [filters?.customer_id, searchOptions]);

    return (
        <SelectDropDown
            onSelect={onSelect}
            onClear={onClear}
            onReset={onResetBtn}
            options={searchOptions}
            placeholder={"Select a customer"}
            searchText={searchText} 
            setSearchText={setSearchText}
            value={value}
            setValue={setValue}
        />
    )
}
